import { SocketApi } from '../../api/socketApi';
import { useEffect } from 'react';
import { userStore } from '../../store/userStore/UserStore';
import { callStore } from '../../store/callStore/CallStore';

export const useIncomingCall = () => {
  const { isSocketLoaded } = userStore;

  useEffect(() => {
    if (isSocketLoaded) {
      SocketApi.getClient()?.on('incomingCall', (res) => {
        console.log('incomingCall', res);
        callStore.setCallData(res);
        callStore.setTestCallData(res);
      });
      SocketApi.getClient()?.on('endIncomingCall', (res) => {
        console.log('endIncomingCall', res);
        callStore.setCallData(null);
        callStore.removeCallData(res.callId);
      });
    }
  }, [isSocketLoaded]);
};
