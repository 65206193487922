import './style/index.scss';
import { observer } from 'mobx-react-lite';

import AppRouter from './routes/AppRouter';
import useScreenSize from './hooks/useScreenSize';
import { useAppErrorController } from './hooks/useAppErrorController';
import { useIsActiveTab } from './hooks/socket/useIsActiveTab';
import { useSetTabActiveStatus } from './hooks/socket/useSetTabActiveStatus';
import { useSetSocketCurrentPage } from './hooks/socket/useSetSocketCurrentPage';
import { useIncomingCall } from './hooks/socket/useIncomingCall';

const App = observer(function App() {
  useIsActiveTab();
  useAppErrorController();
  useSetTabActiveStatus();
  useScreenSize();
  useSetSocketCurrentPage();
  useIncomingCall();

  return <AppRouter />;
});

export default App;
