import {
  DESKTOP_SCREEN,
  MIDDLE_SCREEN,
  VIEW_DESKTOP,
  VIEW_MIDDLE,
  VIEW_MOBILE,
} from '../constants/screen';
import { makeAutoObservable } from 'mobx';

type ScreenType = 'desktop' | 'middle' | 'mobile_closed';

enum LOGO_TYPE {
  VIEW_LOGO_FULL = 'logo_full',
  VIEW_LOGO_MD = 'logo_md',
}

class ScreenStore {
  logo: LOGO_TYPE = LOGO_TYPE.VIEW_LOGO_FULL;
  screenType: ScreenType;
  isMiddleMenu: boolean = false;
  isMobileMenuOpen: boolean = false;
  isMobile: boolean = false;
  isMiddle: boolean = false;
  isDesktop: boolean = false;
  constructor() {
    makeAutoObservable(this);
    this.screenType = this.getTypeOfScreen(window.innerWidth);
    this.isMobile = this.screenType === VIEW_MOBILE;
    this.isMiddle = this.screenType === VIEW_MIDDLE;
    this.isDesktop = this.screenType === VIEW_DESKTOP;
  }

  getTypeOfScreen = (width: number) => {
    if (width >= DESKTOP_SCREEN) {
      return VIEW_DESKTOP;
    }

    if (width >= MIDDLE_SCREEN) {
      return VIEW_MIDDLE;
    }

    return VIEW_MOBILE;
  };

  setScreenType(value: ScreenType) {
    this.screenType = value;
    this.isMobile = this.screenType === VIEW_MOBILE;
    this.isMiddle = this.screenType === VIEW_MIDDLE;
    this.isDesktop = this.screenType === VIEW_DESKTOP;
  }

  changeLogo(screenType: ScreenType) {
    if (screenType === VIEW_MIDDLE) {
      this.logo = LOGO_TYPE.VIEW_LOGO_MD;
    } else {
      this.logo = LOGO_TYPE.VIEW_LOGO_FULL;
    }
  }
  mobileMenuToggle() {
    if (this.screenType === VIEW_MOBILE)
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  changeMenuType(value: boolean) {
    this.isMiddleMenu = value;
  }

  menuTypeToggle() {
    this.isMiddleMenu = !this.isMiddleMenu;
  }
}

export const screenStore = new ScreenStore();
