import { makeAutoObservable } from 'mobx';
import { PageConfig, PagesType, UserInfo } from '../../types/user/userTypes';
import { fetchCurrentUser } from '../../api/auth/fetchCurrentUser';
import { fetchPages } from '../../api/auth/fetchPages';
import { routesArray } from '../../constants/routesConfigs';
import { LS_API_APP, LS_API_TOKEN } from '../../constants/api';
import { SocketApi } from '../../api/socketApi';
import { authStore, TAuthState } from '../authStore/AuthStore';
import { ROUTES } from '../../routes/routes';
import { NavigateFunction } from 'react-router-dom';
import ApiClient from '../../api/ApiClient';

interface ILogoutUserProps {
  totalLogout?: boolean;
}

class UserStore {
  userInfo: UserInfo | null = null;
  pages: PagesType | null = null;
  currentPage: PageConfig | null = null;
  pageConfigs: PageConfig[] | null = null;
  isLoading: boolean = false;
  isActiveTab: boolean = false;
  isSocketLoaded: boolean = false;

  socketApiService: typeof SocketApi;
  authStoreService: typeof authStore;
  apiService: typeof ApiClient;

  constructor(
    socketApiService: typeof SocketApi,
    authStoreService: typeof authStore,
    apiService: typeof ApiClient,
  ) {
    makeAutoObservable(this);
    this.socketApiService = socketApiService;
    this.authStoreService = authStoreService;
    this.apiService = apiService;
  }

  public setUserInfo(data: UserInfo | null) {
    this.userInfo = data;
  }

  public setPages(data: PagesType | null) {
    this.pages = data;
  }

  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  public setSocketStatus(value: boolean) {
    this.isSocketLoaded = value;
  }

  public setPageConfigs(data: PagesType) {
    const pageConfigsArray = data?.hasErrors ? [] : Object.values(data ?? {});

    this.pageConfigs = pageConfigsArray.map((pageConfig) => {
      const routesList =
        routesArray[pageConfig.sUid as keyof typeof routesArray];

      if (routesList) {
        return {
          ...pageConfig,
          rFront: routesList,
        };
      }
      return pageConfig;
    });
  }

  setIsActiveTab(value: boolean) {
    this.isActiveTab = value;
  }

  setCurrentPage(currentPageData: PageConfig) {
    this.currentPage = currentPageData;
  }

  //totalLogout: boolean = true
  logoutUser({ totalLogout = true }: ILogoutUserProps) {
    if (totalLogout) {
      localStorage.removeItem(LS_API_APP);
      localStorage.removeItem(LS_API_TOKEN);
    }
    this.setUserInfo(null);
    this.setPages(null);
    this.authStoreService.setAuthState(TAuthState.NO_AUTH);
    this.socketApiService.disconnectClient();
  }

  async fetchUserData(currentUrl: string, navigate: NavigateFunction) {
    if (this.apiService.setAuthHeaders() && this.userInfo === null) {
      const currentUser = await fetchCurrentUser();

      if (currentUser && !currentUser.hasErrors) {
        const userPages = await fetchPages();
        if (!userPages.hasErrors) {
          this.setUserInfo(currentUser);
          this.setPages(userPages);
          this.setPageConfigs(userPages);
          this.authStoreService.setAuthState(TAuthState.AUTH_SUCCESS);
          this.authStoreService.setAppIsLoaded();
          this.socketApiService.reconnectToServer();
          if (currentUrl.startsWith('/auth')) {
            navigate(ROUTES.ROOT);
          }
        } else {
          // Удаляем токены потому что они недействительны и переходим на форму авторизации
          this.logoutUser({});
          this.authStoreService.setAuthError('Страницы приложения не найдены!');
          navigate(ROUTES.AUTH);
        }
      } else {
        // Удаляем токены потому что они недействительны и переходим на форму авторизации
        this.logoutUser({});
        this.authStoreService.setAuthError('Пользователь не найден!');
        navigate(ROUTES.AUTH);
      }
    }
  }
}

export const userStore = new UserStore(SocketApi, authStore, ApiClient);
