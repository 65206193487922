import React from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PageConfig } from '../types/user/userTypes';

import { ROUTES } from './routes';
import apiClient from '../api/ApiClient';
import { useAuth } from '../hooks/auth/useAuth';
import { userStore } from '../store/userStore/UserStore';
import { authStore, TAuthState } from '../store/authStore/AuthStore';
import { LOADER_INL_CENTER } from '../constants/loaderTemplate';

import { Auth, NotFound } from '../components/lazy';
import BaseLoader from '../components/helpers/Loader/BaseLoader';
import Layout from '../components/layouts/Layout';

type CustomRouteProps = {
  component: React.ComponentType<any>;
  config?: PageConfig;
};

const CustomRouteElement = ({
  component: Component,
  config,
}: CustomRouteProps) => {
  return <Component config={config} />;
};

const AppRouter = observer(function AppRouter() {
  const { pageConfigs } = userStore;
  const { authState, isAppLoaded } = authStore;

  useAuth();

  return (
    <Routes>
      {authState === TAuthState.NO_AUTH && !apiClient.setAuthHeaders() && (
        <>
          <Route path={ROUTES.AUTH} element={<Auth />} />
          <Route
            path={ROUTES.AUTH_URL}
            element={<BaseLoader template={LOADER_INL_CENTER} />}
          />
          <Route path="*" element={<Navigate to={ROUTES.AUTH} />} />
        </>
      )}

      {authState === TAuthState.NO_AUTH && apiClient.setAuthHeaders() && (
        <Route path="*" element={<BaseLoader template={LOADER_INL_CENTER} />} />
      )}

      {isAppLoaded && authState === TAuthState.AUTH_SUCCESS && (
        <>
          <Route path={'*'} element={<NotFound />} />
          <Route path={ROUTES.AUTH} element={<Navigate to={ROUTES.ROOT} />} />
          <Route
            path={ROUTES.AUTH_URL}
            element={<Navigate to={ROUTES.ROOT} />}
          />
        </>
      )}

      {authState === TAuthState.AUTH_SUCCESS && (
        <Route path={ROUTES.ROOT} element={<Layout />}>
          {pageConfigs &&
            pageConfigs.length > 0 &&
            pageConfigs.map((el: any) =>
              el.rFront.childRoute ? (
                <Route key={el.sUid} path={el.sUri}>
                  <Route
                    index
                    element={
                      <CustomRouteElement
                        component={el.rFront.component}
                        config={el}
                      />
                    }
                  />
                  <Route
                    path={`${el.sUri}/${el.rFront.childRoute}`}
                    element={
                      <CustomRouteElement
                        component={el.rFront.component}
                        config={el}
                      />
                    }
                  />
                </Route>
              ) : (
                <Route
                  key={el.sUid}
                  path={el.sUri}
                  element={
                    <CustomRouteElement
                      component={el.rFront.component}
                      config={el}
                    />
                  }
                />
              ),
            )}
        </Route>
      )}
    </Routes>
  );
});

export default AppRouter;
