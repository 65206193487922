import { observer } from 'mobx-react-lite';
import {
  LOADER_CUSTOM_CENTER,
  LOADER_DEFAULT,
  LOADER_DIV_CENTER,
  LOADER_INL_CENTER,
} from '../../../constants/loaderTemplate';

const BaseLoader = observer(function BaseLoader({
  template,
}: {
  template: string;
}) {
  const classViewer = [];
  const classContainer = ['py-5', 'px-5'];
  if (template === undefined) template = LOADER_DEFAULT;
  switch (template) {
    case LOADER_INL_CENTER: {
      classContainer.push('d-flex');
      classContainer.push('justify-content-center');
      classContainer.push('vh-100');
      classContainer.push('align-items-center');
      break;
    }

    case LOADER_DIV_CENTER: {
      classContainer.push('d-flex');
      classContainer.push('justify-content-center');
      break;
    }

    case LOADER_CUSTOM_CENTER: {
      classViewer.push('d-flex');
      // classViewer.push('h-100');
      classContainer.push('m-auto');
      break;
    }

    default: {
      classViewer.push('d-inline-flex');
    }
  }

  return (
    <div
      className={classViewer.join(' ')}
      style={{ height: template === LOADER_CUSTOM_CENTER ? '60vh' : 'unset' }}
    >
      <div className={classContainer.join(' ')}>
        <div className={`spinner-border text-primary`} role="status">
          <span className="visually-hidden">Загрузка...</span>
        </div>
      </div>
    </div>
  );
});

export default BaseLoader;
