import apiClient from '../ApiClient';
import { CLIENT_URL } from '../../constants/api';
import { OrganizationListData } from '../../types/organization/organizationList/organizationListTypes';
import { IFilterRequest } from '../../types/filter/filterTypes';

export const fetchOrganizationListData = async (
  nextPage: number,
  filterData: IFilterRequest | null = null,
  sort: number = 0,
): Promise<OrganizationListData> => {
  return await apiClient.runRequestAsync({
    url: CLIENT_URL,
    data: {
      sPresetName: 'clientsList',
      iLoadPage: nextPage,
      rFilterData: filterData,
      iSortPreset: sort,
      // iDebugSleepSec: 22,
    },
  });
};
