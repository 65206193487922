import { Main, OrganizationsPage, Tasks } from '../components/lazy';

export const routesArray = {
  '85859a48-bed0-4618-8de8-68e78cbe6462': {
    component: Main,
    childRoute: '',
  },
  '93798e82-20ba-460c-851e-ffab99cc6ebc': {
    component: OrganizationsPage,
    childRoute: ':uid',
  },
  '6a525a66-09a1-4061-ae24-465703924e80': {
    component: Tasks,
    childRoute: '',
  },
};
