import React, { lazy, Suspense } from 'react';
import { Spinner } from 'react-bootstrap';

const MenuMobileCloseButtonLazy = lazy(
  () =>
    import('../ui/buttons/menu/MenuMobileCloseButton/MenuMobileCloseButton'),
);
const CategoryManagersTabLazy = lazy(
  () =>
    import(
      '../organization/FilterOrganization/filterTabs/GeneralFilterTab/ManagersTabs/CategoryManagersTab'
    ),
);
const FinanceChangeManagerModuleLazy = lazy(
  () =>
    import(
      '../company/financeTab/financeModals/FInanceChangeManager/FinanceChangeManagerModule'
    ),
);

const HeaderSearchLazy = lazy(
  () => import('../layouts/Header/HeaderSearch/HeaderSearch'),
);

const FinanceBillModalMobileLazy = lazy(
  () =>
    import(
      '../company/financeTab/financeModals/FinanceBillModal/FinanceBillModalMobile'
    ),
);
const FinanceBillModalDesktopLazy = lazy(
  () =>
    import(
      '../company/financeTab/financeModals/FinanceBillModal/FinanceBillModalDesktop'
    ),
);
const AuthLazy = lazy(() => import('../../pages/System/Auth/Auth'));
const NotFoundLazy = lazy(() => import('../../pages/System/NotFound/NotFound'));
const ModalErrorLazy = lazy(
  () => import('../helpers/Modal/ModalError/ModalError'),
);

const TasksLazy = lazy(() => import('../../pages/Tasks/Tasks'));
const OrganizationsPageLazy = lazy(
  () => import('../../pages/OrganizationsPage/OrganizationsPage'),
);
const MainLazy = lazy(() => import('../../pages/Main/Main'));
const AdditionalFilterTabLazy = lazy(
  () =>
    import(
      '../organization/FilterOrganization/filterTabs/AdditionalFilterTab/AdditionalFilterTab'
    ),
);
const SalesFilterTabLazy = lazy(
  () =>
    import(
      '../organization/FilterOrganization/filterTabs/SalesFilterTab/SalesFilterTab'
    ),
);
const ModalQrCodeLazy = lazy(
  () => import('../helpers/Modal/ModalQrCode/ModalQrCode'),
);
const AppBreadCrumbsLazy = lazy(
  () => import('../helpers/Breadcrumbs/AppBreadCrumbs'),
);
const AppPlayerLazy = lazy(() => import('../helpers/AppPlayer/AppPlayer'));

const FinancePaymentsModalLazy = lazy(
  () =>
    import(
      '../company/financeTab/financeModals/FinancePaymentsModal/FinancePaymentsModal'
    ),
);
const FinanceSalesModalLazy = lazy(
  () =>
    import(
      '../company/financeTab/financeModals/FinanceSalesModal/FinanceSalesModal'
    ),
);

const FinanceBillModalLazy = lazy(
  () =>
    import(
      '../company/financeTab/financeModals/FinanceBillModal/FinanceBillModal'
    ),
);

const FinanceSalesModalDesktopLazy = lazy(
  () =>
    import(
      '../company/financeTab/financeModals/FinanceSalesModal/FinanceSalesModalDesktop'
    ),
);
const FinanceSalesModalMobileLazy = lazy(
  () =>
    import(
      '../company/financeTab/financeModals/FinanceSalesModal/FinanceSalesModalMobile'
    ),
);

const AddNewOrganizationLazy = lazy(
  () => import('../organization/AddNewOrganization/AddNewOrganization'),
);
const FilterOrganizationLazy = lazy(
  () => import('../organization/FilterOrganization/FilterOrganization'),
);
const GeneralFilterTabLazy = lazy(
  () =>
    import(
      '../organization/FilterOrganization/filterTabs/GeneralFilterTab/GeneralFilterTab'
    ),
);

export const createLazyComponent = <T extends React.ComponentType<any>>(
  LazyComponent: React.LazyExoticComponent<T>,
  fallback?: boolean,
) => {
  return (props: React.ComponentProps<T>, componentProps?: any) => {
    return (
      <Suspense
        fallback={
          fallback ? (
            <div className="w-100 vh-100 d-flex align-items-center justify-content-center z-2">
              <Spinner variant="light" animation="border" />
            </div>
          ) : null
        }
      >
        <LazyComponent {...props} {...componentProps} />
      </Suspense>
    );
  };
};

export const delayImport = (promise: any) => {
  return new Promise((resolve) => {
    setTimeout(resolve, 5000);
  }).then(() => promise);
};

export const MenuMobileCloseButton = createLazyComponent(
  MenuMobileCloseButtonLazy,
);
export const ModalQrCode = createLazyComponent(ModalQrCodeLazy, true);
export const AppBreadCrumbs = createLazyComponent(AppBreadCrumbsLazy);
export const AppPlayer = createLazyComponent(AppPlayerLazy);
export const FinancePaymentsModal = createLazyComponent(
  FinancePaymentsModalLazy,
  true,
);
export const FinanceSalesModal = createLazyComponent(
  FinanceSalesModalLazy,
  true,
);
export const FinanceBillModal = createLazyComponent(FinanceBillModalLazy, true);
export const FinanceSalesModalDesktop = createLazyComponent(
  FinanceSalesModalDesktopLazy,
);
export const FinanceSalesModalMobile = createLazyComponent(
  FinanceSalesModalMobileLazy,
);
export const AddNewOrganization = createLazyComponent(
  AddNewOrganizationLazy,
  true,
);
export const FilterOrganization = createLazyComponent(
  FilterOrganizationLazy,
  true,
);
export const GeneralFilterTab = createLazyComponent(GeneralFilterTabLazy, true);
export const SalesFilterTab = createLazyComponent(SalesFilterTabLazy, true);
export const AdditionalFilterTab = createLazyComponent(
  AdditionalFilterTabLazy,
  true,
);
export const Main = createLazyComponent(MainLazy);
export const OrganizationsPage = createLazyComponent(OrganizationsPageLazy);
export const Tasks = createLazyComponent(TasksLazy);

export const ModalError = createLazyComponent(ModalErrorLazy, true);
export const Auth = createLazyComponent(AuthLazy);
export const NotFound = createLazyComponent(NotFoundLazy);
export const FinanceBillModalDesktop = createLazyComponent(
  FinanceBillModalDesktopLazy,
);
export const FinanceBillModalMobile = createLazyComponent(
  FinanceBillModalMobileLazy,
);
export const HeaderSearch = createLazyComponent(HeaderSearchLazy);
export const FinanceChangeManagerModule = createLazyComponent(
  FinanceChangeManagerModuleLazy,
);
export const CategoryManagersTab = createLazyComponent(
  CategoryManagersTabLazy,
  true,
);

const Index = () => {
  return <div></div>;
};

export default Index;
