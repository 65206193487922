import React from 'react';
import { IGeneralInitialState } from '../../../types/organization/tabs/informationType';

export interface ICompanyProps {
  company: ICompanyData;
}

export interface ICompanyDataResponse {
  aData: ICompanyData;
  hasErrors?: boolean;
}

export interface ICompanyData {
  state: ECompanyMachineState;
  main_data_state: string;
  tab_data_state: string;
  finance_data_state: string;
  id: string;
  rAccess: {
    bBtnEditCard: boolean;
    bBtnSetMeAsMainManager: boolean;
    bHasUniqueSchemas: boolean;
    bTabContacts: boolean;
    bTabFinance: boolean;
    bTabHandlers: boolean;
    bTabHolding: boolean;
    bTabZp: boolean;
    iTotalBlockType: number;
  };
  rInfo: {
    iCntAllContacts: number;
    sClientInn: string;
    sClientKpp: string;
    sFullTitle: string;
    sUidElement: string;
  };
  companyTabs: {
    [key: string]: ICompanyTab;
  };
  currentTab: ECompanyTabKeys;
  financeTab: EFinanceTabKeys;
  tabData: any;
  changeData: any;
  generalInitialState: IGeneralInitialState;
}

export interface ICompanyTab {
  eventKey: ECompanyTabKeys;
  title: string;
  icon: React.ReactNode;
  length?: number;
}

export interface ICompanies {
  [key: string]: ICompanyData;
}

export interface ICompanyCardProps {
  uid: string | null;
  id: string;
  isModal: boolean;
  tabKey?: ECompanyTabKeys;
  financeTabKey?: EFinanceTabKeys;
  isMain?: boolean;
}

export type TCompanyFinanceTabs = Record<
  string,
  {
    eventKey: EFinanceTabKeys;
    title: string;
    icon: string;
  }
>;

export type ICompanyMachine = Record<ECompanyMachineState, any>;

export enum ECompanyMachineState {
  'start' = 'start',
  'loading_data' = 'loading_data',
  'loading_more_data' = 'loading_more_data',
  'main_data' = 'main_data',
  'tab_data' = 'tab_data',
  'finance_data' = 'finance_data',
  'error' = 'error',
  'edit_mode' = 'edit_mode',
  'change_mode' = 'change_mode',
}

export interface ITransitionAction {
  type: ETransitionType;
  id: string;
  uid?: string;
  tabKey?: ECompanyTabKeys;
  financeTabKey?: EFinanceTabKeys;
  isMain?: boolean;
  tabParams?: {
    sPresetName: EFinanceTabKeys;
    aParams: {
      type: string; // "external"
      disableCounts: boolean; // Отключает расчёт кол-ва для производительности
      pageLimit: number; // записей на странице
      iPage: number | null; // номер страницы для загрузки
    };
  };

  [key: string]: any;
}

export enum ETransitionType {
  FETCH_MAIN_DATA = 'FETCH_MAIN_DATA',
  MAIN_DATA_SUCCESS = 'MAIN_DATA_SUCCESS',
  MAIN_DATA_ERROR = 'MAIN_DATA_ERROR',
  FETCH_TAB_DATA = 'FETCH_TAB_DATA',
  TAB_DATA_SUCCESS = 'TAB_DATA_SUCCESS',
  TAB_DATA_ERROR = 'TAB_DATA_ERROR',
  FETCH_FINANCE_DATA = 'FETCH_FINANCE_DATA',
  FETCH_MORE_FINANCE_DATA = 'FETCH_MORE_FINANCE_DATA',
  FINANCE_DATA_SUCCESS = 'FINANCE_DATA_SUCCESS',
  FINANCE_DATA_ERROR = 'FINANCE_DATA_ERROR',
  EDIT_ON = 'EDIT_ON',
  EDIT_OFF = 'EDIT_OFF',
  FETCH_CHANGE_DATA = 'FETCH_CHANGE_DATA',
  CHANGE_DATA_SUCCESS = 'CHANGE_DATA_SUCCESS',
  ERROR = 'ERROR',
}

export enum ECompanyTabKeys {
  'main' = 'main',
  'holding' = 'holding',
  'contacts' = 'contacts',
  'events' = 'events',
  'finance' = 'finance',
  'salary' = 'salary',
}

export enum EFinanceTabKeys {
  'gantTable' = 'gantTable',
  'salesCalendar' = 'salesCalendar',
  'commonDocsList' = 'commonDocsList',
  'clientSalesList' = 'clientSalesList',
}
