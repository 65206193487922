// response.status !== 200
// Нет ответа сервера Модалка Ошибка
export const CL_API_ERR_SERVER = 'crm_cl_api.server_error';

// responseData.errorCode === '000001'
// Вход запрещён. Станица авторизации и сбрасываем ЛС
export const CL_API_ERR_AUTH = 'crm_cl_api.auth_error';

// responseData.errorCode === '000002'
// Вход запрещён. Станица авторизации и сохраняем ЛС
export const CL_API_ERR_AUTH_BLOCK_IP = 'crm_cl_api.auth_error_by_ip';

// '999999' - неизвестная ошибка
// Модалка Ошибка
export const CL_API_ERR_NONAME = 'crm_cl_api.noname_error';

// $sErrorMessage = 'Ошибка исполнения запроса!'; $sErrorCode = '888888';
//Ошибка запроса Модалка ошибки.
export const CL_API_ERR_SQL = 'crm_cl_api.sql_error';

// Нет прав доступа '000003';
export const CL_API_ERR_NO_ACCESS = 'crm_cl_api.access_error';
