import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { modalStore } from '../../store/modal/ModalStore';
import { screenStore } from '../../store/ScreenStore';

import AppModal from '../helpers/Modal/AppModal/AppModal';

const ModalList = observer(function ModalList() {
  const { modalList } = modalStore;
  const { isMobile } = screenStore;

  useEffect(() => {
    window.document.body.style.overflow =
      modalList.length > 0 ? 'hidden' : 'auto';

    if (isMobile) {
      window.document.body.style.overflow =
        modalList.length > 0 ? 'hidden' : 'auto';
    }

    if (!isMobile) {
      window.document.body.style.marginRight =
        modalList.length > 0 ? '17px' : '0';
    }
  }, [isMobile, modalList.length]);

  return (
    <>
      {modalList.length > 0 &&
        modalList.map((modal, index) => (
          <AppModal key={index} closeOverflow={modal.closeOverflow}>
            {modal.children}
          </AppModal>
        ))}
    </>
  );
});

export default ModalList;
