import apiClient from '../ApiClient';
import { USER_URL } from '../../constants/api';
import { UserInfo } from '../../types/user/userTypes';

export const fetchCurrentUser = async (): Promise<UserInfo> => {
  console.log('fetchCurrentUser');
  return await apiClient.runRequestAsync({
    url: USER_URL,
    data: {
      // iDebugSleepSec: 1,
    },
  });
};
