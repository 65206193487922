import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import { BiSolidBellRing } from 'react-icons/bi';
import { BsSearch } from 'react-icons/bs';
import { FaRegEnvelope } from 'react-icons/fa';

import { ROUTES } from '../../../routes/routes';
import { BASE_URL } from '../../../constants/api';
import { img34 } from '../../../constants/images/imageSizes';
import { screenStore } from '../../../store/ScreenStore';
import { userStore } from '../../../store/userStore/UserStore';

import { HeaderSearch } from '../../lazy';

import styles from './Header.module.scss';

const Header = observer(function Header() {
  const { isMobile } = screenStore;
  const { userInfo } = userStore;
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const imgUrl = userInfo?.staff_photos?.[img34]?.uri;

  const menuHandler = () => screenStore.mobileMenuToggle();

  const searchHandler = () => setSearchIsOpen(!searchIsOpen);

  const totalLogoutUser = () => userStore.logoutUser({});

  return (
    <header className={styles.header}>
      <HeaderSearch {...{ searchIsOpen, setSearchIsOpen }} />

      <div className="d-flex gap-2 flex-wrap">
        {isMobile && (
          <Button
            className="rounded-1"
            variant="dark"
            type="button"
            size="lg"
            onClick={menuHandler}
            style={{ minWidth: '50px' }}
          >
            <i className="bi bi-justify" />
          </Button>
        )}

        <Button
          className={styles.header__button}
          variant="outline-dark"
          type="button"
          size="lg"
          onClick={searchHandler}
        >
          <BsSearch size={18} />
        </Button>

        <Button
          className={styles.header__button}
          variant="outline-dark"
          type="button"
          size="lg"
        >
          <BiSolidBellRing size={18} />
        </Button>

        <Button
          className={styles.header__button}
          variant="outline-dark"
          type="button"
          size="lg"
        >
          <FaRegEnvelope size={18} />
        </Button>
      </div>

      <Dropdown>
        <Dropdown.Toggle variant="white" id="dropdown-basic">
          <img
            width={34}
            height={34}
            className="rounded-5"
            src={BASE_URL + imgUrl}
            alt="аватарка сотрудника"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Link
            to={ROUTES.AUTH}
            onClick={totalLogoutUser}
            className="text-decoration-none d-block w-100 px-3"
          >
            Выход
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    </header>
  );
});

export default Header;
