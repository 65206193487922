import { BASE_URL, LS_API_APP, LS_API_TOKEN } from '../constants/api';
import {
  CL_API_ERR_SERVER,
  CL_API_ERR_AUTH,
  CL_API_ERR_AUTH_BLOCK_IP,
  CL_API_ERR_NONAME,
  CL_API_ERR_SQL,
  CL_API_ERR_NO_ACCESS,
} from '../constants/errors';
import axios, { AxiosError } from 'axios';

class ApiClient {
  #settings = {
    baseUrl: BASE_URL,
    baseHeaders: {
      'X-Api-Token': '',
      'X-Api-App': '',
      'Content-Type': 'application/json',
    },
  };

  constructor() {
    this.setAuthHeaders();
  }

  getAuthTokens() {
    return {
      apiToken: this.#settings.baseHeaders['X-Api-Token'],
      apiApp: this.#settings.baseHeaders['X-Api-App'],
    };
  }

  setAuthHeaders() {
    this.#settings.baseHeaders['X-Api-Token'] = localStorage.getItem(
      LS_API_TOKEN,
    ) as string;
    this.#settings.baseHeaders['X-Api-App'] = localStorage.getItem(
      LS_API_APP,
    ) as string;
    return (
      this.#settings.baseHeaders['X-Api-Token'] !== null &&
      this.#settings.baseHeaders['X-Api-App'] !== null &&
      this.#settings.baseHeaders['X-Api-Token'].length !== 0 &&
      this.#settings.baseHeaders['X-Api-App'].length !== 0
    );
  }

  async runRequestAsync(options: any) {
    const url = options.url ?? '';
    const data = options.data ?? {};
    const headers = options.headers ?? {};
    try {
      const response = await axios.post(
        this.#settings.baseUrl + url,
        JSON.stringify(data),
        {
          headers: {
            ...this.#settings.baseHeaders,
            ...headers,
          },
        },
      );

      if (response.status !== 200) {
        window.dispatchEvent(
          new CustomEvent(CL_API_ERR_SERVER, {
            detail: { errMsg: 'Ошибка сервера API' },
          }),
        );
      } else {
        const responseData = response.data;
        if (
          typeof responseData !== 'object' ||
          Array.isArray(responseData) ||
          (responseData.hasErrors ?? true) === true
        ) {
          if (
            responseData.sApiSection !== 'interface' &&
            responseData.sApiHandler !== 'getApiTokensByOneLink'
          ) {
            this.#handlerBackendCodes(responseData);
          }
          return {
            hasErrors: true,
            rResponse: responseData,
          };
        } else {
          return responseData.dataArray;
        }
      }
      return response.data.dataArray;
    } catch (err) {
      if (err instanceof AxiosError) {
        window.dispatchEvent(
          new CustomEvent(CL_API_ERR_SERVER, {
            detail: { errMsg: err.message },
          }),
        );
        return { hasErrors: true, err: err };
      } else
        window.dispatchEvent(
          new CustomEvent(CL_API_ERR_SERVER, {
            // @ts-ignore
            detail: { errMsg: err.errorMessage },
          }),
        );
    }
  }

  #handlerBackendCodes(rDataRequest: any) {
    const aMapErros = {
      '000001': CL_API_ERR_AUTH,
      '000002': CL_API_ERR_AUTH_BLOCK_IP,
      '999999': CL_API_ERR_NONAME,
      '888888': CL_API_ERR_SQL,
      '000003': CL_API_ERR_NO_ACCESS,
    };
    // @ts-ignore
    if (aMapErros[rDataRequest.errorCode ?? '--'] !== undefined) {
      window.dispatchEvent(
        new CustomEvent(
          aMapErros[rDataRequest.errorCode as keyof typeof aMapErros],
          {
            detail: {
              errMsg: rDataRequest.errorMessage,
              errRequest: rDataRequest ?? '',
            },
          },
        ),
      );
    } else {
      // Любая кастомная обработка ошибок
      window.dispatchEvent(
        new CustomEvent(CL_API_ERR_SERVER, {
          detail: { errMsg: rDataRequest.errorMessage },
        }),
      );
    }
  }
}

const apiClient = new ApiClient();

export default apiClient;
