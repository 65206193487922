import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Alert, Tab } from 'react-bootstrap';

import {
  ECompanyMachineState,
  ECompanyTabKeys,
  EFinanceTabKeys,
  ETransitionType,
  ICompanyCardProps,
} from './types/company.types';

import { companyStore } from './store/CompanyStore';
import { screenStore } from '../../store/ScreenStore';
import { LOADER_CUSTOM_CENTER } from '../../constants/loaderTemplate';

import CompanyTitle from './CompanyTitle';
import BaseLoader from '../helpers/Loader/BaseLoader';
import {
  CompanyContactTab,
  CompanyFinanceTab,
  CompanyHoldingTab,
  CompanyInfoTab,
  CompanyTabNavDesktop,
  CompanyTabNavMobile,
} from './companyLazy';

const CompanyCard = observer(function CompanyCard({
  uid,
  id,
  isModal,
  tabKey = ECompanyTabKeys.main,
  financeTabKey = EFinanceTabKeys.gantTable,
  isMain,
}: ICompanyCardProps) {
  const { isMobile } = screenStore;
  const { companies } = companyStore;
  const company = id ? companies[id] : null;
  const STATUSES = {
    TAB_ERROR: company?.tab_data_state === ECompanyMachineState.error,
    TAB_EXIST: company?.tab_data_state === ECompanyMachineState.tab_data,
    TAB_LOADING: company?.tab_data_state === ECompanyMachineState.loading_data,
    MAIN_ERROR: company?.main_data_state === ECompanyMachineState.error,
    MAIN_EXIST: company?.main_data_state === ECompanyMachineState.main_data,
    IS_MAIN: company?.currentTab === ECompanyTabKeys.main,
    IS_HOLDING: company?.currentTab === ECompanyTabKeys.holding,
    IS_CONTACT: company?.currentTab === ECompanyTabKeys.contacts,
    IS_FINANCE: company?.currentTab === ECompanyTabKeys.finance,
  };
  const CARD_STYLE: React.CSSProperties = {
    width: isMobile ? '95%' : isModal ? '75%' : '100%',
    minHeight: '94vh',
    height: isMobile || isModal ? 'auto' : '94vh',
    overflowY: isMobile || isModal ? 'unset' : 'auto',
  };

  useEffect(() => {
    if (id && uid) {
      companyStore.transition({
        uid,
        id: id.toString(),
        type: ETransitionType.FETCH_MAIN_DATA,
        tabKey,
        financeTabKey,
        isMain,
      });
    }
  }, [financeTabKey, id, isMain, tabKey, uid]);

  useEffect(() => {
    return () => {
      companyStore.removeCompany(id);
    };
  }, [id, uid]);

  const onSelectTab = (eventKey: string | null) => {
    const tabKey = ECompanyTabKeys[eventKey as keyof typeof ECompanyTabKeys];
    const type =
      eventKey === ECompanyTabKeys.finance
        ? ETransitionType.FETCH_FINANCE_DATA
        : ETransitionType.FETCH_TAB_DATA;

    if (eventKey && uid) {
      companyStore.transition({
        uid,
        id: id.toString(),
        type,
        tabKey,
        financeTabKey: company?.financeTab ? company.financeTab : financeTabKey,
      });
    }
  };

  return (
    <div
      className={isModal ? 'modal__content' : 'bg-white p-3'}
      style={CARD_STYLE}
    >
      {STATUSES.MAIN_ERROR && <Alert variant="danger">Ошибка загрузки!</Alert>}

      {company && STATUSES.MAIN_EXIST && (
        <>
          <CompanyTitle {...{ company, isModal }} />

          <Tab.Container
            defaultActiveKey={company.currentTab}
            onSelect={onSelectTab}
          >
            {isMobile && <CompanyTabNavMobile {...{ company }} />}

            {!isMobile && <CompanyTabNavDesktop {...{ company }} />}

            {STATUSES.TAB_ERROR && (
              <Alert variant="danger">Ошибка загрузки!</Alert>
            )}

            {STATUSES.TAB_LOADING && (
              <BaseLoader template={LOADER_CUSTOM_CENTER} />
            )}

            {STATUSES.TAB_EXIST && (
              <Tab.Content>
                {STATUSES.IS_MAIN && <CompanyInfoTab {...{ company }} />}

                {STATUSES.IS_HOLDING && <CompanyHoldingTab {...{ company }} />}

                {STATUSES.IS_CONTACT && <CompanyContactTab {...{ company }} />}

                {STATUSES.IS_FINANCE && <CompanyFinanceTab {...{ company }} />}
              </Tab.Content>
            )}
          </Tab.Container>
        </>
      )}

      {!uid && (
        <>
          <Alert variant="secondary" className="rounded-1">
            Продам гараж! 8 800 555 35 35
          </Alert>
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
          {/*<Alert variant="secondary" className="rounded-1">*/}
          {/*  Продам гараж! 8 800 555 35 35*/}
          {/*</Alert>*/}
        </>
      )}
    </div>
  );
});

export default CompanyCard;
