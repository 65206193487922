export interface IFilterRequest {
  importantOptions?: (string | number)[]; //'Важность клиента:'
  categoryOptions?: (string | number)[]; //'Категория ABC:'
  clientTypes?: (string | number)[]; //'Тип клиента:'
  clientStatus?: (string | number)[]; //'Статус клиента:'
  categorySalesList?: (string | number)[]; //'Продажи осуществлялись по категориям:'
  sellersList?: (string | number)[]; //'Получатель платежа:'
  connection1C?: (string | number)[]; //'Связь с 1С:'
  rawData?: (string | number)[]; //'Неразобранные данные:'
  contactInformation?: (string | number)[]; //'Наличие контактной иформации:'
  deleteMark?: (string | number)[]; //'Метка на удаление:'
  salaryScheme?: (string | number)[]; //'Схема начисления ЗП:'
  groupAssociations?: number | null; //'Принадлежность к группам:'
  groupList?: (string | number)[]; //'Список групп:'
  activityAssociations?: number | null; //'Принадлежность к видам деятельности:'
  activityTypes?: (string | number)[]; //'Виды деятельности:'
  globalMarkAssociations?: number | null; //'Принадлежность к глобальным меткам:'
  globalMarks?: (string | number)[]; //'Глобальные метки:'
  documentSearch?: (string | number)[]; //'Искать по документу:'
  landSizeFrom?: number | null; //'Размер земли от (Га):'
  landSizeTo?: number | null; //'Размер земли до (Га):'
  startSales?: Date | null; //'Дата начала продаж:'
  endSales?: Date | null; //'Дата окончания продаж (включительно):'
  startAddToBD?: Date | null; //'Дата добавления в базу с:'
  endAddToBD?: Date | null; //'Дата добавления в базу до:'
  startPayments?: Date | null;
  finishPayments?: Date | null;
  startBills?: Date | null;
  finishBills?: Date | null;
  yearSearch?: number | null; // 'Год:'
  documentNumber?: string; //'Номер:' Номер документа
  organizationTitle?: string; //'В карточке организации содержится (любое точное совпадение):'
  headerGlobalSearch?: string; // Глобальный поиск по документу в хедере приложения
  isSimpleSearch?: boolean | null; // Чекбокс глобального поиска в хедере документа
  companyIdList?: string; // Text area внизу фильтров
  areasFilter?: (string | number)[]; // Поле фильтра областей
  districtFilter?: (string | number)[]; // Поле фильтра регионов
  managersFilter?: (string | number)[]; // Поле фильтра менеджеров
  managersInSalesCategory?: (string | number)[]; // Поле фильтра менеджеров
  showAllManagerClient?: boolean | null;
  sElementUid?: string;
}

export interface IFilterDataResponse {
  aActivitiesList: ActiveListType;
  aAddressTypes: Record<string, IStatus>;
  aCategorySalesList: CategorySalesType;
  aClientGroupsList: ClientGroupListType;
  aClientManagers: ClientManagersType;
  aClientStatusList: ClientStatusType;
  aClientTypes: ClientType;
  aOblList: IOblList;
  aSellersList: SellersListType;
  aGlobalLabelsList: GlobalMarksType;
  aDocYearsList: number[];
  aOrderList: Record<number, SortOptionsItem>;
  aOpfList: Record<string, OpfItem>;
  aCountryCodes: { aItems: any; aRegionCodes: string[] };
}

export type FilterRegionResponse = Record<string, FilterRegionItem>;

export type FilterRegionItem = {
  sOblTitle: string;
  aItems: Record<string, FilterRegionType>;
};

export type FilterRegionType = {
  iIdElement: number;
  sRegionTitle: string;
};

export interface IFilterFieldItem {
  sGroupName: string;
  key: string;
  items: IFilterManager[];
}

export interface IFilterManager {
  title: string;
  value: number;
  searchStr: string;
  key: string;
}

export interface IStatus {
  iIdElement: number;
  sTitle: string;
  sAccessPermissions: string;
  bDisableExit?: boolean;
}

export interface IActiveItem {
  iIdElement: number;
  sItemTitle: string;
}

/* Типы Клиентов ActiveList */
export type ActiveListType = Record<string, IActiveItemList>;

export interface IActiveItemList {
  aItems: Record<string, IActiveItem>;
  sTitleCategory: string;
}

/* Типы Категорий Клиентов CategorySalesList */
export type CategorySalesType = Record<string, ICategorySalesItem>;

export interface ICategorySalesItem {
  iIdElement: number;
  sFullTitle: string;
  sShortTitle: string;
}

/* Типы Группировки Клиентов ClientGroupList */

export type ClientGroupListType = Record<string, IStatus>;

/* Типы  Менеджеров по отделам ClientManagers */

export type ClientManagersType = Record<string, IClientManagerItem>;

export interface IClientManagerItem {
  aItems: Record<string, IManger>;
  sDepartmentTitle: string;
}

export interface IManger {
  iManagerId: number;
  sFullName: string;
  sLastName: string;
  sName: string;
}

/* Типы статуса переговоров с клиентом ClientStatusList */
export type ClientStatusType = Record<string, IStatus>;

/* Типы для типов клиентов ClientTypes*/
export type ClientType = Record<string, IStatus>;

/* Типы областей OblList */
export interface IOblList {
  sCategoryTitle: string;
  aItems: Record<string, IOblItem>;
}

export interface IOblItem {
  iIdElement: number;
  sOblTitle: string;
  sRegionPhoneCode: string;
}

/* Типы организаций SellersList */

export type SellersListType = Record<string, IStatus>;

/* Тип глобальных меток */

export type GlobalMarksType = Record<string, GlobalMarksItems>;

export type GlobalMarksItems = {
  sGroupName: string;
  aItems: Record<string, IStatus>;
};

export type SortOptionsItem = {
  title: string;
};

export type OpfItem = {
  iIdElement: number;
  iSort: number;
  sFullTitle: string;
  sShowTitle: string;
  sTitle: string;
};

export type FilterInputsType = {
  importantOptions?: (string | number)[]; //'Важность клиента:' / number[]
  categoryOptions?: (string | number)[]; //'Категория ABC:'
  clientTypes?: (string | number)[]; //'Тип клиента:'
  clientStatus?: (string | number)[]; //'Статус клиента:'
  categorySalesList?: (string | number)[]; //'Продажи осуществлялись по категориям:'
  sellersList?: (string | number)[]; //'Получатель платежа:'
  connection1C?: (string | number)[]; //'Связь с 1С:'
  rawData?: (string | number)[]; //'Неразобранные данные:'
  contactInformation?: (string | number)[]; //'Наличие контактной иформации:'
  deleteMark?: (string | number)[]; //'Метка на удаление:'
  salaryScheme?: (string | number)[]; //'Схема начисления ЗП:'
  groupAssociations?: number | null; //'Принадлежность к группам:'
  groupList?: (string | number)[]; //'Список групп:'
  activityAssociations?: number | null; //'Принадлежность к видам деятельности:'
  activityTypes?: (string | number)[]; //'Виды деятельности:'
  globalMarkAssociations?: number | null; //'Принадлежность к глобальным меткам:'
  globalMarks?: (string | number)[]; //'Глобальные метки:'
  documentSearch?: string; //'Искать по документу:'
  landSizeFrom?: number | null; //'Размер земли от (Га):'
  landSizeTo?: number | null; //'Размер земли до (Га):'
  startSales?: string | null; //'Дата начала продаж:'
  endSales?: string | null; //'Дата окончания продаж (включительно):'
  startAddToBD?: string | null; //'Дата добавления в базу с:'
  endAddToBD?: string | null; //'Дата добавления в базу до:'
  startPayments?: string | null;
  finishPayments?: string | null;
  startBills?: string | null;
  finishBills?: string | null;
  yearSearch?: number | null; // 'Год:'
  documentNumber?: string; //'Номер:' Номер документа
  organizationTitle?: string; //'В карточке организации содержится (любое точное совпадение): Первый текстовый инпут в фильтрах'
  companyIdList?: string; // Text area внизу фильтров
  areasFilter?: (string | number)[] | null; // Поле фильтра областей
  districtFilter?: (string | number)[]; // Поле фильтра регионов
  managersFilter?: (string | number)[]; // Поле фильтра менеджеров
  managersInSalesCategory?: (string | number)[]; // Поле фильтра менеджеров
  showAllManagerClient?: boolean | null;
};

export enum EGeneralTab {
  mainManager = 'mainManager',
  categoryManager = 'categoryManager',
}

export enum EFilterTabs {
  general,
  sales,
  additional,
}
