import { makeAutoObservable } from 'mobx';

class PlayerStore {
  sourceTracks: string[] = [];
  currentTrackIndex = 0;
  currentTrack: string | null = null;
  isPlaying: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  setSourceTracks(value: string[]) {
    this.removeCurrentTrack();
    this.setCurrentTrackIndex(0);
    this.removeSourceTracks();
    this.sourceTracks = value;
    this.setCurrentTrack();
  }

  removeSourceTracks() {
    this.sourceTracks = [];
  }

  setCurrentTrackIndex(value: number) {
    this.currentTrackIndex = value;
  }
  setCurrentTrack() {
    this.currentTrack = this.sourceTracks[this.currentTrackIndex];
  }

  removeCurrentTrack() {
    this.currentTrack = null;
  }

  playHandler() {
    this.isPlaying = !this.isPlaying;
  }

  setIsPlaying(value: boolean) {
    this.isPlaying = value;
  }

  nextTrackHandler() {
    const newIndex = Math.min(
      this.currentTrackIndex + 1,
      this.sourceTracks.length - 1,
    );
    this.currentTrackIndex = newIndex;
    this.currentTrack = this.sourceTracks[newIndex];
  }

  prevTrackHandler() {
    const newIndex = Math.max(this.currentTrackIndex - 1, 0);
    this.currentTrackIndex = newIndex;
    this.currentTrack = this.sourceTracks[newIndex];
  }
}

export const playerStore = new PlayerStore();
