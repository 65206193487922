import { io, Socket } from 'socket.io-client';
import apiClient from './ApiClient';
import {
  WS_DEFAULT_URL,
  // WS_NODE_URL,
} from '../constants/api';
import { userStore } from '../store/userStore/UserStore';

const registry = new FinalizationRegistry(
  (heldValue: { obj: SocketApiClient }) => {
    heldValue.obj.disconnectClient();
  },
);

class SocketApiClient {
  static instance: SocketApiClient;
  protected socketConnection: Socket | null = null;

  constructor() {
    if (SocketApiClient.instance) {
      return SocketApiClient.instance;
    }
    SocketApiClient.instance = this;
    // this.reconnectToServer();
    registry.register(this, { obj: this });
  }

  public reconnectToServer() {
    const isActiveTab = !document.hidden;

    const uri = WS_DEFAULT_URL;
    // const uri = WS_NODE_URL;
    const path = '/gateway/v1/events/';

    if (this.socketConnection) {
      this.disconnectClient();
    }

    if (apiClient.setAuthHeaders()) {
      const rIoClient = io(uri, {
        path,
        auth: { ...apiClient.getAuthTokens(), isActiveTab },
        autoConnect: true,
        forceNew: false,
        upgrade: true,
        multiplex: true,
        transports: ['websocket', 'polling'],
        rememberUpgrade: true,
      });

      rIoClient.on('connect', () => {
        this.socketConnection = rIoClient;
        userStore.setSocketStatus(true);
      });
    }
  }

  public disconnectClient() {
    if (this.socketConnection) {
      this.socketConnection.close();
      userStore.setSocketStatus(false);
      this.socketConnection = null;
    }
  }

  public getClient(): Socket | null {
    return this.socketConnection;
  }
}

export const SocketApi = new SocketApiClient();
