import { makeAutoObservable } from 'mobx';

export interface ICallData {
  companyContact: IIncomingContact[];
  mainContact: IIncomingContact[];
  // mainOrganizations: IIncomingCompany[];
  // organizations: IIncomingCompany[];
  callId: string;
  contacts: { contact: IIncomingContact; items: IIncomingContact[] };
  organizations: { organization: IIncomingCompany; items: IIncomingCompany[] };
  clientPhone: string;
}

interface IIncomingContact {
  id: number;
  c_position: string;
  name: string;
  date_birthday: any;
  id_company: number;
  create_by: number;
  sort: number;
  uid: string;
}

interface IIncomingCompany {
  company_area: any;
  count_logs: number;
  count_logs_comand: number;
  date_add_to_base: string;
  date_birthday: Date;
  date_last_update: string;
  delete_comment: any;
  description: string;
  dkp: any;
  dpk_timestamp: string;
  form_uid: string;
  full_name: string;
  id: number;
  id_abc: number;
  id_crm: number;
  id_holding: number | null;
  id_last_editor: number;
  id_legal_form: number;
  id_manager: number;
  id_obl: number;
  id_region: number | null;
  id_seller: number;
  id_status: number;
  id_sync: number;
  id_type: number;
  inn: string;
  is_vip: number;
  kpp: string;
  label_delete: boolean;
  name: string;
  opf: string;
  site_url: string;
  trash: any;
  uid: string;
}

class CallStore {
  callData: ICallData | null = null;
  testCallData: Record<string, ICallData> = {};

  constructor() {
    makeAutoObservable(this);
  }

  setCallData(data: any | null) {
    this.callData = data;
  }
  setTestCallData(data: any) {
    this.testCallData[data.callId] = data;
  }
  removeCallData(callId: string) {
    delete this.testCallData[callId];
  }
}

export const callStore = new CallStore();
