import { makeAutoObservable } from 'mobx';
import {
  FilterRegionResponse,
  IFilterDataResponse,
  SortOptionsItem,
} from '../../types/filter/filterTypes';
import { filterService } from './filterService/FilterService';
import { userStore } from '../userStore/UserStore';

export interface IDropdownOption {
  label: string;
  value: number;
  isDisabled?: boolean;
}

interface IAreasOptions {
  label: string;
  items: { value: any; label: string }[];
}

class FilterOptionsStore {
  categorySalesListOptions: IDropdownOption[] = [];
  sellersListOptions: IDropdownOption[] = [];
  clientStatusOptions: IDropdownOption[] = [];
  clientTypesOptions: IDropdownOption[] = [];
  addressOptions: IDropdownOption[] = [];
  areasOptions: IAreasOptions[] = [];
  districtOptions: IAreasOptions[] = [];
  areasOptionsWithCode: IAreasOptions[] = [];
  opfOptions: IDropdownOption[] = [];
  activityTypesOptions: IAreasOptions[] = [];
  groupManagersOptions: IAreasOptions[] = [];
  yearSearchOptions: IDropdownOption[] = [];
  groupListOptions: IDropdownOption[] = [];
  globalMarksOptions: IDropdownOption[] = [];
  addTypesOptions: IDropdownOption[] = [];
  sortByOptions: [string, SortOptionsItem][] = [];

  currentActivityList: { label: string; value: number }[] = [];

  userStoreService: typeof userStore;

  constructor(userStoreService: typeof userStore) {
    makeAutoObservable(this);
    this.userStoreService = userStoreService;
  }

  setFilterOptions(data: IFilterDataResponse) {
    this.categorySalesListOptions = filterService.getDefaultOptions(
      data.aCategorySalesList,
      'sFullTitle',
    );
    this.sellersListOptions = filterService.getDefaultOptions(
      data.aSellersList,
    );
    this.clientStatusOptions = filterService.getDefaultOptions(
      data.aClientStatusList,
    );
    this.clientTypesOptions = filterService.getDefaultOptions(
      data.aClientTypes,
    );
    this.groupListOptions = filterService.getDefaultOptions(
      data.aClientGroupsList,
      'sTitle',
      'iIdElement',
      'bDisableExit',
    );
    this.addressOptions = filterService.getDefaultOptions(data.aAddressTypes);
    this.yearSearchOptions =
      data.aDocYearsList.map((el) => ({ label: String(el), value: el })) || [];
    this.sortByOptions = Object.entries(data.aOrderList || []);
  }

  setGroupOptions(data: IFilterDataResponse) {
    this.globalMarksOptions = filterService.getGroupOptions(
      data.aGlobalLabelsList,
      'sTitle',
      'iIdElement',
    );
  }

  setGroupOptionsWithTitle(data: IFilterDataResponse) {
    this.areasOptions = filterService.getGroupOptionsWithTitle(data.aOblList);
    this.areasOptionsWithCode = filterService.getGroupOptionsWithTitle(
      data.aOblList,
      'sCategoryTitle',
      'sOblTitle',
      'iIdElement',
      'sRegionPhoneCode',
    );
    this.activityTypesOptions = filterService.getGroupOptionsWithTitle(
      data.aActivitiesList,
      'sTitleCategory',
      'sItemTitle',
      'iIdElement',
    );
    this.groupManagersOptions = filterService.getGroupOptionsWithTitle(
      data.aClientManagers,
      'sDepartmentTitle',
      'sFullName',
      'iManagerId',
    );
  }

  setDistrictOptions(data: FilterRegionResponse) {
    this.districtOptions = filterService.getGroupOptionsWithTitle(
      data,
      'sOblTitle',
      'sRegionTitle',
      'iIdElement',
    );
  }

  resetDistrictOptions() {
    this.districtOptions = [];
  }

  setOpfOptions(data: IFilterDataResponse) {
    this.opfOptions = filterService.getOpfOptions(data.aOpfList);
  }

  setAddTypesOptions(data: IFilterDataResponse) {
    this.addTypesOptions = Object.values(data.aClientTypes)
      .filter((el) =>
        Object.values(
          this.userStoreService.userInfo?.permissions
            ? this.userStoreService.userInfo?.permissions
            : [],
        ).includes(el.sAccessPermissions),
      )
      .map((el) => ({ label: el.sTitle, value: el.iIdElement }));
  }

  setCurrentActivityList(clientType: number) {
    const currentType = this.clientTypesOptions.find(
      (el) => el.value === clientType,
    );
    const currentList = this.activityTypesOptions.find(
      (el) => el.label === currentType?.label,
    );
    this.currentActivityList = currentList ? currentList.items : [];
  }

  resetCurrentActivityList() {
    this.currentActivityList = [];
  }
}

export const filterOptionsStore = new FilterOptionsStore(userStore);
