import { observer } from 'mobx-react-lite';
import { Dropdown } from 'react-bootstrap';

import { callStore, ICallData } from '../../../../store/callStore/CallStore';
import { modalStore } from '../../../../store/modal/ModalStore';

import CloseModalButton from '../../../ui/buttons/CloseModalButton';

import styles from './ToastIncomingCall.module.scss';
import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import { Link } from 'react-router-dom';
import CompanyCard from '../../../company/CompanyCard';

const ToastIncomingCall = observer(function ToastIncomingCall() {
  const { testCallData } = callStore;
  const { modalList } = modalStore;
  const closeTestHandler = (callId: string) => callStore.removeCallData(callId);

  const testClick = (uid: string, callId?: string) => {
    const id = Date.now().toString();

    modalStore.openModal({
      children: (
        <CompanyCard uid={uid} isModal={true} id={id} />
        // <ModalOrganizationProvider>
        //   <OrganizationCard uid={uid} isModal={true} />
        // </ModalOrganizationProvider>
      ),
    });
    console.log(callId);
    // callStore.removeCallData(callId);
  };

  const testClick1 = (callData: ICallData, idCompany: number) => {
    const allOrganizations = [
      callData.organizations.organization,
      ...callData.organizations.items,
    ];
    const curOrg = allOrganizations.find((el) => el.id === idCompany);

    console.log(curOrg);
    if (curOrg) {
      const id = Date.now().toString();

      modalStore.openModal({
        children: (
          // <ModalOrganizationProvider>
          //   <TestIncCont uid={curOrg.form_uid} />
          // </ModalOrganizationProvider>
          <CompanyCard uid={curOrg.form_uid} isModal={true} id={id} />
        ),
      });
    }
    // callStore.removeCallData(callId);
  };

  return (
    <>
      {testCallData && Object.values(testCallData).length > 0 && (
        <div
          className="position-fixed bottom-0 bg-white p-2 rounded-1 border border-2 border-primary d-flex flex-column gap-2 m-1"
          style={{
            zIndex: 1100,
            right: modalList.length > 0 ? '17px' : '0',
          }}
        >
          {Object.values(testCallData).map((callData, index) => {
            return (
              <div key={index} className={`${styles.toast__card} app__fs`}>
                <div className="d-flex align-items-center gap-2">
                  <h6 className="mb-0 text-nowrap">Входящий звонок:</h6>
                  {callData.contacts.items && (
                    <Link
                      className="text-decoration-none text-nowrap"
                      to={`tel:+${callData.clientPhone}`}
                    >
                      <PhoneInput
                        inputClass="bg-transparent border-0 m-0 p-0 w-100 text-primary"
                        buttonClass="d-none"
                        specialLabel={''}
                        localization={ru}
                        placeholder="Телефон"
                        value={callData.clientPhone}
                        inputStyle={{
                          pointerEvents: 'none',
                          lineHeight: '15.34px',
                          height: 'unset',
                        }}
                      />
                    </Link>
                  )}

                  <div className="ms-auto">
                    <CloseModalButton
                      handler={() => closeTestHandler(callData.callId)}
                    />
                  </div>
                </div>

                {callData.contacts.contact && (
                  <>
                    <p>
                      <span className="fw-bold me-1">Должность:</span>
                      {callData.contacts.contact.c_position}
                    </p>
                    <p>
                      <span className="fw-bold me-1">Имя:</span>
                      {callData.contacts.contact.name}
                    </p>
                  </>
                )}

                <div>
                  <span className="app__fs fw-bold me-1">Организация: </span>
                  {callData.organizations.organization && (
                    <span
                      className={styles.toast__company_title}
                      onClick={() =>
                        testClick(
                          callData.organizations.organization.form_uid,
                          callData.callId,
                        )
                      }
                    >
                      {callData.organizations.organization.full_name}
                    </span>
                  )}
                </div>

                {callData.contacts.items.length > 0 && (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-primary py-0"
                      size="sm"
                      className={styles.toast__additional_title}
                    >
                      Другие контакты...
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {callData.contacts.items.map((el) => (
                        <Dropdown.Item
                          key={el.id}
                          onClick={() => testClick1(callData, el.id_company)}
                        >
                          <p>
                            <span className="fw-bold me-1">Должность:</span>
                            {el.c_position}
                          </p>
                          <p>
                            <span className="fw-bold me-1">Имя:</span>
                            {el.name}
                          </p>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {callData.organizations.items.length > 0 && (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-primary py-0"
                      size="sm"
                      className={styles.toast__additional_title}
                    >
                      Другие организации...
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {callData.organizations.items.map((el) => (
                        <Dropdown.Item
                          key={el.id}
                          onClick={() =>
                            testClick(el.form_uid, callData.callId)
                          }
                        >
                          {el.full_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
});

export default ToastIncomingCall;
