import { useEffect } from 'react';
import { SocketApi } from '../../api/socketApi';
import { userStore } from '../../store/userStore/UserStore';

export const useSetSocketCurrentPage = () => {
  const { currentPage, isSocketLoaded } = userStore;

  useEffect(() => {
    if (isSocketLoaded && currentPage) {
      SocketApi.getClient()?.emit('setCurrentPage', { data: currentPage.sUid });
    }
  }, [currentPage, isSocketLoaded]);
};
