import { IChangeMachine } from '../types/change.types';

export const changeStateMachine: IChangeMachine = {
  start: {
    FETCH_CHANGE_GENERAL_DATA: 'loading_change_data',
    FETCH_CHANGE_ADDRESS_DATA: 'loading_change_data',
    FETCH_CHANGE_CONTACT_DATA: 'loading_change_data',
    FETCH_CHANGE_DATA: 'loading_change_data',
    FETCH_CHANGE_TECHNIC_DATA: 'loading_change_data',
    FETCH_CHANGE_MANAGER_DATA: 'loading_change_data',
  },
  loading_change_data: {
    SUCCESS: 'edit',
  },
  edit: {
    EDIT_CLOSE: 'start',
    FETCH_CHANGE_MANAGER_DATA: 'loading_change_data',
  },
  error: {},
};
