export interface IChanges {
  [key: string]: IChangeData;
}

export interface IChangeData {
  status: EChangeMachineState;
  changeData: any;
}

export type IChangeMachine = Record<EChangeMachineState, any>;

export enum EChangeMachineState {
  'start' = 'start',
  'loading_change_data' = 'loading_change_data',
  'error' = 'error',
  'edit' = 'edit',
}

export interface IChangeTransitionAction {
  type: EChangeTransitionType;
  id: string;
  [key: string]: any;
}

export enum EChangeTransitionType {
  FETCH_CHANGE_GENERAL_DATA = 'FETCH_CHANGE_GENERAL_DATA',
  FETCH_CHANGE_ADDRESS_DATA = 'FETCH_CHANGE_ADDRESS_DATA',
  FETCH_CHANGE_CONTACT_DATA = 'FETCH_CHANGE_CONTACT_DATA',
  FETCH_CHANGE_DATA = 'FETCH_CHANGE_DATA',
  FETCH_CHANGE_TECHNIC_DATA = 'FETCH_CHANGE_TECHNIC_DATA',
  FETCH_CHANGE_MANAGER_DATA = 'FETCH_CHANGE_MANAGER_DATA',
  SUCCESS = 'SUCCESS',
  EDIT_CLOSE = 'EDIT_CLOSE',
}
