import { Button } from 'react-bootstrap';
import { GrClose } from 'react-icons/gr';
import { FaPencil } from 'react-icons/fa6';

import {
  ECompanyMachineState,
  ECompanyTabKeys,
  ETransitionType,
  ICompanyData,
} from './types/company.types';

import { companyStore } from './store/CompanyStore';

import TouchButton from '../ui/buttons/TouchButton';
import CloseModalButton from '../ui/buttons/CloseModalButton';

const CompanyTitle = ({
  company,
  isModal,
}: {
  company: ICompanyData;
  isModal: boolean;
}) => {
  const isEdit = company?.state === ECompanyMachineState.edit_mode;
  const isMain = company?.currentTab === ECompanyTabKeys.main;
  const isContacts = company?.currentTab === ECompanyTabKeys.contacts;

  const editHandler = () => {
    companyStore.transition({
      uid: company?.rInfo?.sUidElement,
      id: company?.id,
      type: isEdit ? ETransitionType.EDIT_OFF : ETransitionType.EDIT_ON,
    });
  };

  return (
    <>
      <div className="w-100 d-flex gap-2 mb-2">
        <h3 style={{ lineHeight: '28.8px' }}>{company?.rInfo?.sFullTitle}</h3>

        {(isMain || isContacts) && (
          <div>
            <TouchButton
              variant={isEdit ? 'outline-danger' : 'warning'}
              className="border-0 p-2"
              onClick={editHandler}
            >
              {isEdit ? <GrClose /> : <FaPencil />}
            </TouchButton>
          </div>
        )}

        <div className="ms-auto">{isModal && <CloseModalButton />}</div>
      </div>

      {company?.rAccess?.bBtnSetMeAsMainManager && (
        <div className="text-start">
          <Button
            size="lg"
            variant="outline-primary"
            className="rounded-1 mb-2"
          >
            Взять в работу
          </Button>
        </div>
      )}
    </>
  );
};

export default CompanyTitle;
