import apiClient from '../ApiClient';
import { PAGES_URL } from '../../constants/api';
import { PagesType } from '../../types/user/userTypes';

export const fetchPages = async (): Promise<PagesType> => {
  return await apiClient.runRequestAsync({
    url: PAGES_URL,
    data: {
      // iDebugSleepSec: 1,
    },
  });
};
