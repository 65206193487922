import React from 'react';
import { observer } from 'mobx-react-lite';

import { modalStore } from '../../../../store/modal/ModalStore';
import { screenStore } from '../../../../store/ScreenStore';
import { playerStore } from '../../../../store/PlayerStore/PlayerStore';

import styles from './AppModal.module.scss';

interface ITestModal {
  children: React.ReactNode | null;
  closeOverflow?: boolean;
}

const AppModal = observer(function AppModal({
  children,
  closeOverflow = true,
}: ITestModal) {
  const { modalOverflow } = modalStore;
  const { isMobile } = screenStore;
  const { sourceTracks } = playerStore;

  const closeModalHandler = (e: any) => {
    if (e.target.closest('.modal__content') === null && closeOverflow) {
      modalStore.closeModal();
    }
  };

  return (
    <div
      className={styles.overlay}
      style={{
        overflowY: modalOverflow,
        paddingRight: modalOverflow === 'hidden' && !isMobile ? '17px' : 0,
        padding: sourceTracks.length > 0 ? '2rem 0 140px' : '2rem 0 4rem',
      }}
      onMouseDown={closeModalHandler}
    >
      {children}
    </div>
  );
});

export default AppModal;
