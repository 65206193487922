import { InformationTabType } from '../../../types/organization/tabs/informationType';
import { ContactTabItem } from '../../../types/organization/tabs/contactType';
import apiClient from '../../../api/ApiClient';
import { TABS_URL } from '../../../constants/api';
import { ECompanyTabKeys } from '../types/company.types';

export type FetchCompanyTabResponse<T extends ECompanyTabKeys> =
  T extends ECompanyTabKeys.main
    ? InformationTabType
    : T extends ECompanyTabKeys.holding
      ? any
      : T extends ECompanyTabKeys.contacts
        ? ContactTabItem
        : T extends ECompanyTabKeys.finance
          ? any
          : null;

export const fetchCompanyTab = async <T extends ECompanyTabKeys>(
  uid: string | undefined,
  tabName: T,
  tabParams?: {},
): Promise<FetchCompanyTabResponse<T>> => {
  return await apiClient.runRequestAsync({
    url: TABS_URL,
    data: {
      sTabName: tabName,
      sClientUid: uid,
      ...tabParams,
      // iDebugSleepSec: 0.5,
    },
  });
};
