import { ICompanyMachine } from '../types/company.types';

export const companyStateMachine: ICompanyMachine = {
  start: {
    FETCH_MAIN_DATA: 'loading_data',
  },
  loading_data: {
    MAIN_DATA_SUCCESS: 'main_data',
    MAIN_DATA_ERROR: 'error',
    TAB_DATA_SUCCESS: 'tab_data',
    TAB_DATA_ERROR: 'error',
    FINANCE_DATA_SUCCESS: 'finance_data',
    FINANCE_DATA_ERROR: 'error',
    ERROR: 'error',
    CHANGE_DATA_SUCCESS: 'change_mode',
  },
  loading_more_data: {
    FINANCE_DATA_SUCCESS: 'finance_data',
    TAB_DATA_SUCCESS: 'tab_data',
  },
  main_data: {
    FETCH_TAB_DATA: 'loading_data',
    FETCH_FINANCE_DATA: 'loading_data',
  },
  tab_data: {
    FETCH_TAB_DATA: 'loading_data',
    FETCH_FINANCE_DATA: 'loading_data',
    FETCH_MORE_FINANCE_DATA: 'loading_more_data',
    EDIT_ON: 'edit_mode',
  },
  finance_data: {
    TAB_DATA_SUCCESS: 'tab_data',
  },
  error: {
    FETCH_TAB_DATA: 'loading_data',
    FETCH_FINANCE_DATA: 'loading_data',
  },
  edit_mode: {
    EDIT_OFF: 'tab_data',
    FETCH_TAB_DATA: 'loading_data',
    FETCH_FINANCE_DATA: 'loading_data',
    FETCH_CHANGE_DATA: 'change_mode',
  },
  change_mode: {
    FETCH_TAB_DATA: 'loading_data',
  },
};
