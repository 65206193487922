import { GrClose } from 'react-icons/gr';

import { ButtonVariant, OutlineVariant } from '../../../types/ui/buttons';

import { modalStore } from '../../../store/modal/ModalStore';

import TouchButton from './TouchButton';

import styles from './TestButton.module.scss';

const CloseModalButton = ({
  variant = 'outline-dark',
  handler,
}: {
  variant?: keyof typeof ButtonVariant | keyof typeof OutlineVariant;
  handler?: (arg: any) => void;
}) => {
  const closeHandler = () => modalStore.closeModal();

  return (
    <TouchButton
      variant={variant}
      onClick={handler ? handler : closeHandler}
      className={styles.close}
    >
      <GrClose />
    </TouchButton>
  );
};

export default CloseModalButton;
