import { VIEW_DESKTOP } from '../../../../constants/screen';
import { Button } from 'react-bootstrap';
import { screenStore } from '../../../../store/ScreenStore';
import { observer } from 'mobx-react-lite';

const MenuToggleButton = observer(function MenuToggleButton({
  isHovered,
  middleMenuToggleHandler,
}: {
  isHovered: boolean;
  middleMenuToggleHandler: () => void;
}) {
  const { screenType, isMiddleMenu } = screenStore;

  return (
    <>
      {screenType === VIEW_DESKTOP && (
        <div
          className={`d-flex arrow__button mt-auto py-3 h-25 
          ${isHovered ? 'justify-content-end' : ''} 
          ${isMiddleMenu ? '' : 'justify-content-end'} 
          ${isMiddleMenu && !isHovered ? 'justify-content-center' : ''}`}
        >
          <Button
            className="py-1 px-2 border-0 rounded-1 d-inline-flex align-items-center fs-4 mt-auto"
            variant="outline-light"
            title={isMiddleMenu ? 'Развернуть меню' : 'Свернуть меню'}
            onClick={middleMenuToggleHandler}
          >
            {isMiddleMenu ? <span>&#10095;</span> : <span>&#10094;</span>}
          </Button>
        </div>
      )}
    </>
  );
});

export default MenuToggleButton;
