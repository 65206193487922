import { useEffect, useState } from 'react';

import { VIEW_MOBILE } from '../../../constants/screen';
import { screenStore } from '../../../store/ScreenStore';

import scroll_btn from '../../../assets/images/scroll_btn.png';
import styles from './ScrollToTopButton.module.scss';

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);
  const { screenType } = screenStore;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100 && screenType === VIEW_MOBILE) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [screenType]);

  return (
    <>
      {showButton && (
        <button className={styles.container} onClick={scrollToTop}>
          <img className={styles.icon} src={scroll_btn} alt="scroll-button" />
        </button>
      )}
    </>
  );
};

export default ScrollToTopButton;
