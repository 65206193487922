import apiClient from '../ApiClient';
import { FILTER_URL } from '../../constants/api';
import { IFilterDataResponse } from '../../types/filter/filterTypes';

export enum filterDataPresets {
  FULL_FILTER = 'fullFilter',
  CHANGE_MANAGER = 'forChangeClientManager',
}

export const fetchFilterData = async (
  preset: filterDataPresets,
): Promise<IFilterDataResponse> => {
  return await apiClient.runRequestAsync({
    url: FILTER_URL,
    data: {
      sPresetName: preset,
      // iDebugSleepSec: 5,
    },
  });
};
