import {
  ECompanyTabKeys,
  ICompanyDataResponse,
  ICompanyTab,
} from '../types/company.types';
import { BsInfoSquare } from 'react-icons/bs';
import { PiTreeStructure } from 'react-icons/pi';
import { TiContacts } from 'react-icons/ti';
import { MdEventNote } from 'react-icons/md';
import { GrMoney } from 'react-icons/gr';
import { FcMoneyTransfer } from 'react-icons/fc';

export const getTabData = (response: ICompanyDataResponse) => {
  const rTabs: { [key: string]: ICompanyTab } = {};

  rTabs[ECompanyTabKeys.main] = {
    eventKey: ECompanyTabKeys.main,
    title: 'Информация',
    icon: <BsInfoSquare size={16} />,
  };

  if (response.aData.rAccess.bTabHolding) {
    rTabs[ECompanyTabKeys.holding] = {
      eventKey: ECompanyTabKeys.holding,
      title: 'Структура холдинга',
      icon: <PiTreeStructure size={16} />,
    };
  }

  if (response.aData.rAccess.bTabContacts) {
    rTabs[ECompanyTabKeys.contacts] = {
      eventKey: ECompanyTabKeys.contacts,
      title: 'Контакты',
      icon: <TiContacts size={16} />,
      length: response.aData.rInfo.iCntAllContacts,
    };
  }

  if (response.aData.rAccess.bTabHandlers) {
    rTabs[ECompanyTabKeys.events] = {
      eventKey: ECompanyTabKeys.events,
      title: 'События',
      icon: <MdEventNote size={16} />,
    };
  }

  if (response.aData.rAccess.bTabFinance) {
    rTabs[ECompanyTabKeys.finance] = {
      eventKey: ECompanyTabKeys.finance,
      title: 'Финансы',
      icon: <GrMoney size={16} />,
    };
  }

  if (response.aData.rAccess.bTabZp) {
    rTabs[ECompanyTabKeys.salary] = {
      eventKey: ECompanyTabKeys.salary,
      title: 'ЗП модуль',
      icon: <FcMoneyTransfer size={16} />,
    };
  }

  return rTabs;
};
