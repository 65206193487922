export const rStructureMap = {
  grouping: {
    sGroupName: 'sDepartmentTitle',
    items: 'aItems',
    key: '@key',
  },
  items: {
    value: 'iManagerId',
    title: 'sFullName',
    key: '@key',
  },
};

export const rStructureAreas = {
  grouping: {
    sGroupName: 'sCategoryTitle',
    items: 'aItems',
    key: '@key',
  },
  items: {
    value: 'iIdElement',
    title: 'sOblTitle',
    key: '@key',
  },
};

export const rStructureDistrict = {
  grouping: {
    sGroupName: 'sOblTitle',
    items: 'aItems',
    key: '@key',
  },
  items: {
    value: 'iIdElement',
    title: 'sRegionTitle',
    key: '@key',
  },
};
