import { makeAutoObservable } from 'mobx';
import { ChangeModalProps, ModalItem } from '../../types/modal/modalTypes';

class ModalStore {
  modalList: ModalItem[] = [];
  modalOverflow: 'scroll' | 'hidden' = 'scroll';
  constructor() {
    makeAutoObservable(this);
  }

  openModal(modalData: any) {
    this.modalList.push(modalData);
  }
  closeModal() {
    this.modalList = this.modalList.slice(0, this.modalList.length - 1);
  }

  changeModalStyle(data: ChangeModalProps) {
    const filteredModal = this.modalList.find((el) => el.name === data.name);
    if (filteredModal) {
      filteredModal.style = data.style;
      const index = this.modalList.indexOf(filteredModal);
      this.modalList[index] = filteredModal;
    }
  }

  setModalOverflow(value: 'scroll' | 'hidden') {
    this.modalOverflow = value;
  }
}

export const modalStore = new ModalStore();
