import { Outlet } from 'react-router-dom';

import { AppBreadCrumbs, AppPlayer } from '../lazy';

import Header from './Header/Header';
import Menu from './Menu/Menu';
import ModalList from './ModalList';
import LayoutWrapper from './LayoutWrapper';
import ScrollToTopButton from '../ui/ScrollToTopButton/ScrollToTopButton';
import ToastIncomingCall from '../helpers/Toasts/ToastIncomigCall/ToastIncomingCall';

function Layout() {
  return (
    <LayoutWrapper>
      <ToastIncomingCall />
      <Menu />
      <div className="flex-grow-1">
        <Header />
        <div className="container-fluid">
          <AppBreadCrumbs />
          <div className="mt-0 mt-xl-0 ">
            <Outlet />
          </div>
        </div>
      </div>
      <ModalList />
      <ScrollToTopButton />
      <AppPlayer />
    </LayoutWrapper>
  );
}

export default Layout;
