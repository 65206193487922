import { useCallback, useEffect } from 'react';
import { userStore } from '../../store/userStore/UserStore';

export const useIsActiveTab = () => {
  const handleVisibilityChange = useCallback(
    () => userStore.setIsActiveTab(!document.hidden),
    [],
  );

  useEffect(() => {
    handleVisibilityChange();
  }, [handleVisibilityChange]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);
};
