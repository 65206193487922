import { observer } from 'mobx-react-lite';
import { screenStore } from '../../../../store/ScreenStore';
import {
  MAIN_LOGO_MD_URI,
  MAIN_LOGO_URI,
} from '../../../../constants/images/logo-main';

import styles from './MenuLogo.module.scss';

const MenuLogo = observer(function MenuLogo({
  isHovered,
}: {
  isHovered: boolean;
}) {
  const { isMiddleMenu } = screenStore;
  const logoSrc = isMiddleMenu
    ? isHovered
      ? MAIN_LOGO_URI
      : MAIN_LOGO_MD_URI
    : MAIN_LOGO_URI;

  return (
    <div className="text-center mb-3">
      <img
        className={isHovered ? styles.menu__animation : ''}
        src={logoSrc}
        style={{
          height: isHovered ? '58px' : !isMiddleMenu ? '58px' : '38px',
        }}
        alt="Логотип CRM Rosberg"
      />
    </div>
  );
});

export default MenuLogo;
