import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import apiClient from '../../api/ApiClient';
import { userStore } from '../../store/userStore/UserStore';
import { authStore, TAuthState } from '../../store/authStore/AuthStore';

export const useAuth = () => {
  const { pathname: currentUrl } = useLocation();
  const navigate = useNavigate();
  const { userInfo } = userStore;
  const { authQlToken, authState } = authStore;
  const validUrl = authStore.getValidatedAuthUrl(currentUrl);

  // Авторизация по ссылке
  useEffect(() => {
    if (validUrl) {
      userStore.logoutUser({ totalLogout: true });
      authStore
        .fetchToken(validUrl.token, navigate)
        .then(async () => await userStore.fetchUserData(currentUrl, navigate));
    }
  }, [authState, navigate, currentUrl, validUrl]);

  // Авторизация через форму
  useEffect(() => {
    if (authQlToken && authState === TAuthState.NO_AUTH) {
      authStore
        .fetchToken(authQlToken, navigate)
        .then(async () => await userStore.fetchUserData(currentUrl, navigate))
        .finally(() => authStore.setQlToken(''));
    }
  }, [authQlToken, authState, navigate, currentUrl]);

  // Получение данных авторизованного пользователя
  useEffect(() => {
    userStore
      .fetchUserData(currentUrl, navigate)
      .finally(() => userStore.setIsLoading(false));
  }, [navigate, currentUrl, userInfo]);
};
