import React from 'react';
import { FinanceTabPresets } from '../../../api/finance/fetchFinanceTabData';

export type GantTableResponse = {
  aData: GantTableData[] | Doc1cData[] | SalesCalendarData[] | SalesListData[];
  iCurrentPage: number;
  iNextPage: number | null;
  iQueryTotalCount: number;
  iTotalPage: number;
};

export type GantTableData = {
  iAmountPayments: number;
  iAmountSales: number;
  iAmountCorr: number;
  iIdReg: number;
  iSaldoEnd: number;
  rDocDate: { date: string; timezone_type: number; timezone: string };
  sDocNumber: string;
  sDocUid: string;
  sTableTitle: string;
  iColorZoneId: 1 | 2 | 3 | null;
  sColorZoneTitle: string;
};

export type Doc1cData = {
  id: number;
  uid: string;
  date_doc_timestamp: string;
  date_1c: string;
  code_1c: string;
  bill_sum: number | null;
  pay_sum: number | null;
  sale_sum: number | null;
};

export type SalesCalendarData = {
  iIdElement: number;
  sUidElement: string;
  sSaleDocNumber: string;
  rSaleDocDate: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  rSalePaymentDate: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  iDaysDelayPayment: number;
  iSumAmount: number;
  iReceivableZoneId: number;
  bAllowEditManager: boolean;
};

export type SalesListData = {
  iIdElement: number;
  sUidElement: string;
  bSaldo0: boolean;
  bSaldo1: boolean;
  sSaleDocTypeShotName: string;
  sSaleDocNumber: string;
  rSaleDocDate: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  rSalePaymentDate: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  iSumAmount: number;
  iSumFullPurchase: number;
  iSumFullProfit: number;
  iReceivableZoneId: number;
  sSaleCategoryAbbr: string;
  sSellerAbbr: string;
  sManagerCachedName: string;
};

export type FinancePaymentsModalData = {
  bAllowChangeManager: boolean;
  iDocAmount: number;
  iIdElement: number;
  iManagerId: number;
  rDateCreate: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  s1CClientInn: string;
  s1CClientKpp: string;
  s1CClientTitle: string;
  sDetailText: string;
  sDocNumber: string;
  sManagerLastName: string;
  sManagerName: string;
  sSellerTitle: string;
  sUidElement: string;
  hasErrors?: boolean;
  dataMessage?: string;
};

export type FinanceSalesModalItem = {
  iIdElement: number;
  iNomenclatureId: number;
  sNomenclatureTitle: string;
  bIsVirtualReceipt: boolean;
  bIsServiceType: boolean;
  iProductCount: number;
  iPricePurchase: number;
  iPriceSale: number;
  iAmountSale: number;
  iAmountOrigPurchase: number;
  iAmountOrigProfit: number;
  fFactorValue: number;
  iFactorAmountPurchase: number;
  iTotalProfit: number;
  sReceiptDocNumber: string;
  iReceiptDocDate: number;
  iTotalPurchase: number;
  rReceiptDocDate: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  iIdSource: number;
  sSourceTitle: string;
};

export type FinanceSalesModalData = {
  rHead: {
    iIdElement: number;
    sUidElementFrom1C: string;
    sSaleDocNumber: string;
    rSaleDocDate: {
      date: string;
      timezone_type: number;
      timezone: string;
    };
    iSaleManagerId: number;
    sSaleCategoryTitle: string;
    iSellerId: number;
    sSellerName: string;
    sOneCFullTitle: string;
    sOneCInn: string;
    sOneCKpp: string;
    bSaldo0: boolean;
    bSaldo1: boolean;
    iDaysDeferment: number;
    iReceivableZoneId: number;
    iDaysDelayPayment: number;
    iSumAmount: number;
    iReceivableAmount: number;
    sImportHandlerTitle: string;
    sStockFullTitle: string;
    bAllowEditManager: boolean;
    rSalePaymentDate: {
      date: string;
      timezone_type: number;
      timezone: string;
    };
  };
  rUserDetail: {
    iUserId: number;
    sUserUid: string;
    sUserName: string;
    sUserLastName: string;
    sUserSecondName: string;
    bUserActive: boolean;
    iStaffId: number;
    aStaffPhotoCache: {
      '34x34': string;
      '50x50': string;
    };
  };

  aItems: {
    basic: FinanceSalesModalItem[];
    external: FinanceSalesModalItem[];
  };

  hasErrors?: boolean;
  dataMessage?: string;
};

export type FinanceBillModalData = {
  uid: string;
  bill_id: number;
  c_code: string;
  date_format: string;
  date_timestamp: number;
  seller_name: string;
  c_title: string;
  c_inn: string;
  c_kpp: string;
  nds_in_price: number;
  id_manager: number;
  manager_full_name: string;
  bill_items: BillItem[];
  hasErrors?: boolean;
  dataMessage?: string;
  bAllowEditManager: boolean;
};

export type BillItem = {
  id: number;
  nds: number;
  count: number;
  price: number;
  vendor: string;
  row_sum: number;
  nomenclature: string;
};

export type FinanceTabType = {
  eventKey: FinanceTabPresets;
  title: string;
  icon: React.ReactNode;
  component?: React.ReactNode | any;
};

export type SalesModalProps = {
  tabData: FinanceSalesModalData;
};

export type PaymentsModalProps = {
  tabData: FinancePaymentsModalData;
};

export enum FinanceDataLimit {
  MOBILE = 10,
  DESKTOP = 100,
}
