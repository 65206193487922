import apiClient from '../../../api/ApiClient';
import { CLIENT_URL } from '../../../constants/api';
import { ICompanyDataResponse } from '../types/company.types';

export const fetchCompanyData = async (
  uid: string | null,
): Promise<ICompanyDataResponse> => {
  return await apiClient.runRequestAsync({
    url: CLIENT_URL,
    data: {
      sPresetName: 'clientTabs',
      rFilterData: {
        sElementUid: uid,
      },
      // iDebugSleepSec: 555,
    },
  });
};
