import apiClient from '../ApiClient';
import { FILTER_URL } from '../../constants/api';

export const fetchRegionsData = async (areas: number[]) => {
  if (areas !== undefined && areas.length > 0) {
    return await apiClient.runRequestAsync({
      url: FILTER_URL,
      data: {
        sPresetName: 'getOblRegions',
        aOblList: areas,
        // iDebugSleepSec: 20,
      },
    });
  }
};
