import { makeAutoObservable, toJS } from 'mobx';

import { InformationTabType } from '../../../types/organization/tabs/informationType';
import { ContactTabItem } from '../../../types/organization/tabs/contactType';
import {
  EChangeMachineState,
  EChangeTransitionType,
  IChangeTransitionAction,
  IChanges,
} from '../types/change.types';

import { fetchOrganizationListData } from '../../../api/organizationList/fetchOrganizationListData';
import { changeStateMachine } from '../constants/changeStateMachine';
import {
  fetchFilterData,
  filterDataPresets,
} from '../../../api/filter/fetchFilterData';

class ChangeStore {
  changes: IChanges = {};

  constructor() {
    makeAutoObservable(this);
  }

  transition(action: IChangeTransitionAction) {
    const id = action.id;

    const currentChangeState = this.changes[id]
      ? this.changes[id].status
      : EChangeMachineState.start;

    const testNextChangeState =
      changeStateMachine[currentChangeState][action.type];

    if (testNextChangeState) {
      const nextState = this.command(testNextChangeState, action);

      const existId = action.type === 'EDIT_CLOSE' ? null : action.id;

      if (existId) {
        this.changes[existId] = {
          status: testNextChangeState,
          changeData: { ...nextState?.data },
        };
      }
    }

    console.log(
      'change state transition',
      toJS(this.changes),
      'action type',
      action.type,
    );
  }

  command(nextState: any, action: IChangeTransitionAction) {
    // console.log('ACTION TYPE', toJS(action));
    switch (nextState) {
      case EChangeMachineState.start:
        if (action.type === EChangeTransitionType.EDIT_CLOSE) {
          delete this.changes[action.id];
        }
        break;

      case EChangeMachineState.loading_change_data:
        if (action.type === EChangeTransitionType.FETCH_CHANGE_GENERAL_DATA) {
          this.setEditGeneralState(action.data).then((response) => {
            this.transition({
              type: EChangeTransitionType.SUCCESS,
              id: action.id,
              data: response,
            });
          });
        }

        if (action.type === EChangeTransitionType.FETCH_CHANGE_ADDRESS_DATA) {
          this.setAddressInitialState(action.data).then((response) => {
            this.transition({
              type: EChangeTransitionType.SUCCESS,
              id: action.id,
              data: response,
            });
          });
        }

        if (action.type === EChangeTransitionType.FETCH_CHANGE_CONTACT_DATA) {
          this.setContactInitialState(action.data).then((response) => {
            this.transition({
              type: EChangeTransitionType.SUCCESS,
              id: action.id,
              data: response,
            });
          });
        }

        if (action.type === EChangeTransitionType.FETCH_CHANGE_DATA) {
          this.setDataInitialState(action.data).then((response) => {
            this.transition({
              type: EChangeTransitionType.SUCCESS,
              id: action.id,
              data: response,
            });
          });
        }

        if (action.type === EChangeTransitionType.FETCH_CHANGE_TECHNIC_DATA) {
          this.setTechnicInitialState(action.data).then((response) => {
            this.transition({
              type: EChangeTransitionType.SUCCESS,
              id: action.id,
              data: response,
            });
          });
        }

        if (action.type === EChangeTransitionType.FETCH_CHANGE_MANAGER_DATA) {
          this.setManagerInitialState(action.data).then((response) => {
            this.transition({
              type: EChangeTransitionType.SUCCESS,
              id: action.id,
              data: response,
            });
          });
        }

        break;

      case EChangeMachineState.edit:
        if (action.type === EChangeTransitionType.EDIT_CLOSE) {
          delete this.changes[action.id];
        }

        if (action.data) {
          return { data: action.data };
        }
        break;

      default:
        break;
    }
  }

  async setEditGeneralState(data: InformationTabType) {
    const currentHolding = data.id_parent
      ? await fetchOrganizationListData(1, {
          companyIdList: `${data.id_parent}`,
        })
      : null;

    return {
      clientTypes: data?.company_type_id,
      clientStatus: data?.company_status_id,
      sellersList: data?.seller_id,
      categoryOptions: data?.cut_abc_id,
      importantOptions: data?.b_client_vip ? 1 : 0,
      holding:
        data.id_parent && currentHolding
          ? Object.values(currentHolding.aData)
          : null,
      activityTypes: [],
      inn: data?.inn,
      kpp: data?.kpp,
      duplicatedArray: null,
    };
  }

  async setAddressInitialState(data: InformationTabType) {
    return {
      areas: { areasValue: data?.id_obl, code: data?.region_phone_code }, // Нужен ли код вообще?
      // areas: data?.id_obl,
      district: data?.id_region,
      organizationAddresses: data?.json_addresses?.map((el) => ({
        ...el,
        object_type: el.object_type_id,
        chosen: false,
        selected: false,
      })),
    };
  }

  async setDataInitialState(data: InformationTabType) {
    return {
      landSize: data?.company_area,
      organizationLink: data?.site_url,
      activityTypes: data?.json_activities?.map((el) => el.id_activity),
      organizationComment: data?.description,
      // foundationDate: data?.date_birthday ? new Date(data.date_birthday) : null,
      foundationDate: data?.date_birthday ? data?.date_birthday : null,
      groupList: data?.json_groups?.map((el) => el.id_group),
      inn: data?.inn,
      kpp: data?.kpp,
      duplicatedArray: null,
    };
  }

  async setTechnicInitialState(data: InformationTabType) {
    return data?.json_techniks?.reduce((acc, curr) => {
      // @ts-ignore
      acc[curr.uid as keyof typeof acc] = {
        technicType: curr.id_type,
        technicMark: curr.id_type === 4 ? curr.brand : curr.brand_id,
        technicModel: curr.id_type === 4 ? curr.model : curr.model_id,
        technicCount: curr.count,
        technicComment: curr.comment,
      };
      return acc;
    }, {});
  }

  async setManagerInitialState(data: InformationTabType) {
    const response = await fetchFilterData(filterDataPresets.CHANGE_MANAGER);
    const mainManager = data.main_manager;
    const categoryManager = data.json_cat_sales_managers
      ? Object.values(data.json_cat_sales_managers)
      : [];
    const selectedManagerList = [mainManager, ...categoryManager];
    const disabledManagerList = [mainManager.id];
    const disabledCategoryList = [
      ...categoryManager.map((manager) => manager.cat_id),
    ];

    console.log('fetchFilterData', 'categoryManager', categoryManager);
    console.log('fetchFilterData', 'selectedManagerList', selectedManagerList);

    const managerList = Object.values(response.aClientManagers || {}).flatMap(
      (el) => ({
        label: el.sDepartmentTitle,
        items: Object.values(el.aItems).flatMap((items) => ({
          label: items.sFullName,
          value: items.iManagerId,
        })),
      }),
    );

    const categoryList = Object.values(
      response.aCategorySalesList || {},
    ).flatMap((el) => ({
      label: el.sFullTitle,
      value: el.iIdElement,
    }));

    return {
      managerList,
      categoryList,
      mainManager,
      categoryManager,
      selectedManagerList,
      disabledManagerList,
      disabledCategoryList,
    };
  }

  async setContactInitialState(data: ContactTabItem[]) {
    return data.map((contactData) => ({
      c_position: contactData.c_position,
      birthday: contactData.birthday,
      firstName: contactData.name.split(' ')[0],
      secondName: contactData.name.split(' ')[1],
      lastName: contactData.name.split(' ')[2],
      chosen: false,
      selected: false,
      phones: contactData.phones
        ? contactData.phones?.map((phone) => ({
            ...phone,
            chosen: false,
            selected: false,
            phone: `${phone.code}${phone.phone}`,
          }))
        : [],
      emails: contactData.emails
        ? contactData.emails.map((email) => ({
            ...email,
            chosen: false,
            selected: false,
          }))
        : [],
    }));
  }
}

export const changeStore = new ChangeStore();
