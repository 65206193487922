export enum OutlineVariant {
  'outline-success' = 'bg-success text-white',
  'outline-primary' = 'bg-primary text-white',
  'outline-secondary' = 'bg-secondary text-white',
  'outline-danger' = 'bg-danger text-white',
  'outline-info' = 'bg-info text-white',
  'outline-warning' = 'bg-warning text-dark',
  'outline-light' = 'bg-light text-white',
  'outline-dark' = 'bg-dark text-white',
}

export enum ButtonVariant {
  'success' = '#157347',
  'primary' = '#143c82',
  'secondary' = '#5c636a',
  'danger' = 'opacity-75',
  'info' = 'opacity-75',
  'warning' = 'opacity-75',
  'light' = 'opacity-75',
  'dark' = '#424649',
}
