import { lazy } from 'react';
import { createLazyComponent } from '../../lazy';

const CompanyFinanceTabNavDesktopLazy = lazy(
  () => import('../financeTab/CompanyFinanceTabNavDesktop'),
);
const CompanyFinanceTabNavMobileLazy = lazy(
  () => import('../financeTab/CompanyFinanceTabNavMobile'),
);
const CompanyGantTableDesktopLazy = lazy(
  () => import('../financeTab/companyGantTable/CompanyGantTableDesktop'),
);
const CompanyGantTableCorrModalLazy = lazy(
  () => import('../financeTab/companyGantTable/CompanyGantTableCorrModal'),
);
const CompanyGantTableLazy = lazy(
  () => import('../financeTab/companyGantTable/CompanyGantTable'),
);
const CompanyGantTableMobileLazy = lazy(
  () => import('../financeTab/companyGantTable/CompanyGantTableMobile'),
);
const CompanyCardLazy = lazy(() => import('../CompanyCard'));
const CompanySalesCalendarDesktopLazy = lazy(
  () =>
    import('../financeTab/companySalesCalendar/CompanySalesCalendarDesktop'),
);
const CompanySalesCalendarMobileLazy = lazy(
  () => import('../financeTab/companySalesCalendar/CompanySalesCalendarMobile'),
);
const CompanyCommonDocsListDesktopLazy = lazy(
  () =>
    import('../financeTab/companyCommonDocsList/CompanyCommonDocsListDesktop'),
);
const CompanyCommonDocsListMobileLazy = lazy(
  () =>
    import('../financeTab/companyCommonDocsList/CompanyCommonDocsListMobile'),
);
const CompanySalesCalendarLazy = lazy(
  () => import('../financeTab/companySalesCalendar/CompanySalesCalendar'),
);
const CompanyCommonDocsListLazy = lazy(
  () => import('../financeTab/companyCommonDocsList/CompanyCommonDocsList'),
);
const CompanyFinanceTabLazy = lazy(
  () => import('../financeTab/CompanyFinanceTab'),
);
const CompanyFinanceTabMoreButtonLazy = lazy(
  () => import('../financeTab/CompanyFinanceTabMoreButton'),
);
const CompanySalesListDesktopLazy = lazy(
  () => import('../financeTab/companySalesList/CompanySalesListDesktop'),
);
const CompanySalesListMobileLazy = lazy(
  () => import('../financeTab/companySalesList/CompanySalesListMobile'),
);
const CompanySalesListLazy = lazy(
  () => import('../financeTab/companySalesList/CompanySalesList'),
);
const CompanyContactTabLazy = lazy(
  () => import('../contactTab/CompanyContactTab'),
);
const CompanyContactEditButtonLazy = lazy(
  () => import('../contactTab/CompanyContactEditButton'),
);
const CompanyContactDesktopLazy = lazy(
  () => import('../contactTab/CompanyContactDesktop'),
);
const CompanyContactMobileLazy = lazy(
  () => import('../contactTab/CompanyContactMobile'),
);
const CompanyInfoTabLazy = lazy(() => import('../infoTab/CompanyInfoTab'));
const CompanyHoldingTabLazy = lazy(
  () => import('../holdingTab/CompanyHoldingTab'),
);
const CompanyTabNavDesktopLazy = lazy(() => import('../CompanyTabNavDesktop'));
const CompanyTabNavMobileLazy = lazy(() => import('../CompanyTabNavMobile'));
const CompanyGlobalMarksInfoLazy = lazy(
  () => import('../infoTab/infoBlocks/CompanyGlobalMarksInfo'),
);
const CompanySync1CLazy = lazy(
  () => import('../infoTab/companyStatuses/CompanySync1C'),
);
const CompanyDeleteMarkLazy = lazy(
  () => import('../infoTab/companyStatuses/CompanyDeleteMark'),
);
const CompanyBlockWarningLazy = lazy(
  () => import('../infoTab/companyStatuses/CompanyBlockWarning'),
);
const CompanyBlockFullLazy = lazy(
  () => import('../infoTab/companyStatuses/CompanyBlockFull'),
);
const CompanyChangeGeneralLazy = lazy(
  () => import('../infoTab/companyChange/CompanyChangeGeneral'),
);
const CompanyChangeAddressLazy = lazy(
  () => import('../infoTab/companyChange/CompanyChangeAddress'),
);
const CompanyChangeDataLazy = lazy(
  () => import('../infoTab/companyChange/CompanyChangeData'),
);
const CompanyChangeTechnicLazy = lazy(
  () => import('../infoTab/companyChange/CompanyChangeTechnic'),
);
const CompanyTechnicInfoDesktopLazy = lazy(
  () => import('../infoTab/infoBlocks/CompanyTechnicInfoDesktop'),
);
const CompanyTechnicInfoMobileLazy = lazy(
  () => import('../infoTab/infoBlocks/CompanyTechnicInfoMobile'),
);
const CompanyChangeManagerLazy = lazy(
  () => import('../infoTab/companyChange/CompanyChangeManager'),
);
const CompanyChangeContactLazy = lazy(
  () => import('../contactTab/CompanyChangeContact'),
);
export const CompanyFinanceTabNavDesktop = createLazyComponent(
  CompanyFinanceTabNavDesktopLazy,
);
export const CompanyFinanceTabNavMobile = createLazyComponent(
  CompanyFinanceTabNavMobileLazy,
);
export const CompanyGantTableDesktop = createLazyComponent(
  CompanyGantTableDesktopLazy,
);
export const CompanyGantTableCorrModal = createLazyComponent(
  CompanyGantTableCorrModalLazy,
  true,
);
export const CompanyGantTable = createLazyComponent(CompanyGantTableLazy);
export const CompanyGantTableMobile = createLazyComponent(
  CompanyGantTableMobileLazy,
);
export const CompanyCard = createLazyComponent(CompanyCardLazy);
export const CompanySalesCalendarDesktop = createLazyComponent(
  CompanySalesCalendarDesktopLazy,
);
export const CompanySalesCalendarMobile = createLazyComponent(
  CompanySalesCalendarMobileLazy,
);
export const CompanyCommonDocsListDesktop = createLazyComponent(
  CompanyCommonDocsListDesktopLazy,
);
export const CompanyCommonDocsListMobile = createLazyComponent(
  CompanyCommonDocsListMobileLazy,
);
export const CompanySalesCalendar = createLazyComponent(
  CompanySalesCalendarLazy,
);
export const CompanyCommonDocsList = createLazyComponent(
  CompanyCommonDocsListLazy,
);
export const CompanyFinanceTab = createLazyComponent(CompanyFinanceTabLazy);
export const CompanyFinanceTabMoreButton = createLazyComponent(
  CompanyFinanceTabMoreButtonLazy,
);
export const CompanySalesListDesktop = createLazyComponent(
  CompanySalesListDesktopLazy,
);
export const CompanySalesListMobile = createLazyComponent(
  CompanySalesListMobileLazy,
);
export const CompanySalesList = createLazyComponent(CompanySalesListLazy);
export const CompanyContactTab = createLazyComponent(CompanyContactTabLazy);
export const CompanyContactEditButton = createLazyComponent(
  CompanyContactEditButtonLazy,
);
export const CompanyContactDesktop = createLazyComponent(
  CompanyContactDesktopLazy,
);
export const CompanyContactMobile = createLazyComponent(
  CompanyContactMobileLazy,
);
export const CompanyInfoTab = createLazyComponent(CompanyInfoTabLazy);
export const CompanyHoldingTab = createLazyComponent(CompanyHoldingTabLazy);
export const CompanyTabNavDesktop = createLazyComponent(
  CompanyTabNavDesktopLazy,
);
export const CompanyTabNavMobile = createLazyComponent(CompanyTabNavMobileLazy);
export const CompanyGlobalMarksInfo = createLazyComponent(
  CompanyGlobalMarksInfoLazy,
);
export const CompanySync1C = createLazyComponent(CompanySync1CLazy);
export const CompanyDeleteMark = createLazyComponent(CompanyDeleteMarkLazy);
export const CompanyBlockWarning = createLazyComponent(CompanyBlockWarningLazy);
export const CompanyBlockFull = createLazyComponent(CompanyBlockFullLazy);
export const CompanyTechnicInfoDesktop = createLazyComponent(
  CompanyTechnicInfoDesktopLazy,
);
export const CompanyTechnicInfoMobile = createLazyComponent(
  CompanyTechnicInfoMobileLazy,
);
export const CompanyChangeGeneral = createLazyComponent(
  CompanyChangeGeneralLazy,
  true,
);
export const CompanyChangeAddress = createLazyComponent(
  CompanyChangeAddressLazy,
  true,
);
export const CompanyChangeData = createLazyComponent(
  CompanyChangeDataLazy,
  true,
);
export const CompanyChangeTechnic = createLazyComponent(
  CompanyChangeTechnicLazy,
  true,
);
export const CompanyChangeManager = createLazyComponent(
  CompanyChangeManagerLazy,
  true,
);
export const CompanyChangeContact = createLazyComponent(
  CompanyChangeContactLazy,
  true,
);

const Index = () => {
  return (
    // @ts-ignore
    <div />
  );
};

export default Index;
