import { makeAutoObservable, toJS } from 'mobx';
import {
  ECompanyMachineState,
  ECompanyTabKeys,
  ETransitionType,
  ICompanies,
  ICompanyData,
  ITransitionAction,
} from '../types/company.types';
import { fetchCompanyData } from '../api/fetchCompany';
import { fetchCompanyTab } from '../api/fetchCompanyTab';
import { getTabData } from '../utils/getTabData';
import { screenStore } from '../../../store/ScreenStore';
import { FinanceDataLimit } from '../../../types/organization/tabs/financeTypes';
import { companyStateMachine } from '../constants/companyStateMachine';
import { changeStore } from './ChangeStore';
import {
  EChangeMachineState,
  EChangeTransitionType,
} from '../types/change.types';

class CompanyStore {
  companies: ICompanies = {};
  mainCompany: ICompanyData | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  transition(action: ITransitionAction) {
    const id = action.id;

    const currentCompanyState = this.companies[id]
      ? this.companies[id].state
      : ECompanyMachineState.start;

    const nextCompanyState: ECompanyMachineState =
      companyStateMachine[currentCompanyState][action.type];

    if (nextCompanyState) {
      const nextState = this.command(nextCompanyState, action);

      // Добавление данных полученных после выполнения command()
      this.companies[id] = {
        ...this.companies[id],
        state: nextCompanyState,
        ...nextState?.data,
      };

      // Добавление информации для основной организации
      if (action.type === ETransitionType.MAIN_DATA_SUCCESS && action.isMain) {
        this.mainCompany = { ...nextState?.data };
      }

      // Регистрация промежуточных состояний
      if (
        action.type === ETransitionType.MAIN_DATA_SUCCESS ||
        action.type === ETransitionType.MAIN_DATA_ERROR
      ) {
        this.companies[id].main_data_state = nextCompanyState;
      }

      if (
        action.type === ETransitionType.TAB_DATA_SUCCESS ||
        action.type === ETransitionType.FETCH_TAB_DATA ||
        action.type === ETransitionType.TAB_DATA_ERROR
      ) {
        this.companies[id].tab_data_state = nextCompanyState;
      }
      if (
        action.type === ETransitionType.FINANCE_DATA_SUCCESS ||
        action.type === ETransitionType.FETCH_FINANCE_DATA ||
        action.type === ETransitionType.FETCH_MORE_FINANCE_DATA ||
        action.type === ETransitionType.FINANCE_DATA_ERROR
      ) {
        this.companies[id].finance_data_state = nextCompanyState;
      }

      console.log(
        'company state transition',
        toJS(this.companies),
        'action type',
        action.type,
      );
    }
  }

  command(nextState: ECompanyMachineState, action: ITransitionAction) {
    // console.log('ACTION TYPE', action);
    switch (nextState) {
      case ECompanyMachineState.loading_data:
        // Загрузка данных в зависимости от типа action
        if (action.type === ETransitionType.FETCH_MAIN_DATA) {
          this.getMainData(action);
        }

        if (action.type === ETransitionType.FETCH_TAB_DATA) {
          this.getTabData(action);
        }

        if (
          action.type === ETransitionType.FETCH_FINANCE_DATA ||
          action.type === ETransitionType.FETCH_MORE_FINANCE_DATA
        ) {
          this.getFinanceData(action);
        }

        if (action.data) {
          return { data: action.data };
        }
        break;

      case ECompanyMachineState.loading_more_data:
        // Загрузка следующий данных для пагинации в финансах
        if (action.type === ETransitionType.FETCH_MORE_FINANCE_DATA) {
          this.getFinanceData(action);
        }
        if (action.data) {
          return { data: action.data };
        }
        break;

      // Возврат значений при переходе в текущее состояние
      case ECompanyMachineState.main_data:
        if (action.data) {
          return { data: action.data };
        }
        break;

      case ECompanyMachineState.tab_data:
        if (action.data) {
          return { data: action.data };
        }
        break;

      case ECompanyMachineState.finance_data:
        if (action.data) {
          return { data: action.data };
        }
        break;

      case ECompanyMachineState.error:
        if (action.data) {
          return { data: action.data };
        }
        break;

      default:
        break;
    }
  }

  getMainData(action: ITransitionAction) {
    const { uid, id, tabKey, financeTabKey } = action;

    if (uid) {
      fetchCompanyData(uid).then((response) => {
        if (response.hasErrors) {
          // Перевод состояния в статус ошибки
          this.transition({ type: ETransitionType.MAIN_DATA_ERROR, id, uid });
        } else {
          const companyTabs = getTabData(response);
          const existCurrentTab = tabKey
            ? companyTabs[tabKey]
              ? tabKey
              : ECompanyTabKeys.main
            : ECompanyTabKeys.main;
          const data = {
            ...response.aData,
            currentTab: existCurrentTab,
            financeTab: financeTabKey,
            companyTabs,
            id: id,
          };

          // Перевод состояния и передача данных загрузки доступа к вкладкам организации
          this.transition({
            id,
            uid,
            type: ETransitionType.MAIN_DATA_SUCCESS,
            data: { ...data },
            isMain: action.isMain,
          });

          // Перевод состояния для загрузки информации текущей вкладки
          this.transition({
            type:
              existCurrentTab === ECompanyTabKeys.finance
                ? ETransitionType.FETCH_FINANCE_DATA
                : ETransitionType.FETCH_TAB_DATA,
            uid,
            id,
            tabKey: existCurrentTab,
            financeTabKey,
          });
        }
      });
    }
  }

  // Загрузка данных для вкладок "Информация", "Холдинг", "Контакты"
  getTabData(action: ITransitionAction) {
    const { uid, id, tabKey } = action;

    if (uid && tabKey) {
      fetchCompanyTab(uid, tabKey).then(async (response) => {
        if (response.hasErrors) {
          // Перевод состояния в статус ошибки
          this.transition({ id, uid, type: ETransitionType.TAB_DATA_ERROR });
        } else {
          // Перевод состояния в статус загрузки для изменения менеджера
          if (
            tabKey === ECompanyTabKeys.main &&
            changeStore.changes[id] &&
            changeStore.changes[id].status === EChangeMachineState.edit
          ) {
            changeStore.transition({
              type: EChangeTransitionType.FETCH_CHANGE_MANAGER_DATA,
              id,
              data: response,
            });
          }

          // Перевод состояния и передача данных, при успешном получении данных текущей вкладки
          this.transition({
            uid,
            id,
            type: ETransitionType.TAB_DATA_SUCCESS,
            data: {
              currentTab: ECompanyTabKeys[tabKey],
              tabData: response,
            },
          });
        }
      });
    }
  }

  getFinanceData(action: ITransitionAction) {
    const { uid, id, tabKey, financeTabKey } = action;
    const company = this.companies[id];
    const limit = screenStore.isMobile
      ? FinanceDataLimit.MOBILE
      : FinanceDataLimit.DESKTOP;

    const financeTabParams = {
      sPresetName: financeTabKey,
      aParams: {
        type: 'default', // "external"
        disableCounts: false, // Отключает расчёт кол-ва для производительности
        pageLimit: limit, // записей на странице
        iPage:
          action.type === ETransitionType.FETCH_MORE_FINANCE_DATA
            ? company.tabData.iNextPage
            : 1, // номер страницы для загрузки
      },
    };

    if (uid && tabKey) {
      fetchCompanyTab(uid, tabKey, financeTabParams).then((response) => {
        if (response.hasErrors) {
          this.transition({
            id,
            uid,
            type: ETransitionType.FINANCE_DATA_ERROR,
            data: {
              currentTab: ECompanyTabKeys[tabKey],
              financeTab: financeTabKey,
            },
          });
        } else {
          const data = Array.isArray(response.aData) ? response.aData : [];
          const aData = company.tabData?.aData
            ? [...company.tabData.aData, ...data]
            : data;
          const newValue = { ...response, aData };

          this.transition({
            uid,
            id,
            type: ETransitionType.FINANCE_DATA_SUCCESS,
            data: {
              currentTab: ECompanyTabKeys[tabKey],
              financeTab: financeTabKey,
              tabData:
                action.type === ETransitionType.FETCH_MORE_FINANCE_DATA
                  ? newValue
                  : response,
            },
          });

          this.transition({
            id,
            uid,
            type: ETransitionType.TAB_DATA_SUCCESS,
          });
        }
      });
    }
  }

  removeCompany(id: string | null) {
    if (id) {
      delete this.companies[id];
      console.log('this.companies', toJS(this.companies));
    }
  }

  removeMainCompany() {
    this.mainCompany = null;
  }
}

export const companyStore = new CompanyStore();
