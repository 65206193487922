import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { ButtonVariant, OutlineVariant } from '../../../types/ui/buttons';

type TouchButtonProps = {
  children: React.ReactNode;
  variant: keyof typeof ButtonVariant | keyof typeof OutlineVariant;
  className?: string;
  touchClass?: string;
  [name: string]: any;
};

const TouchButton = ({
  children,
  variant,
  className,
  touchClass,
  ...rest
}: TouchButtonProps) => {
  const [isTouched, setIsTouched] = useState(false);

  const handleTouchStart = () => {
    setIsTouched(true);
  };

  const handleTouchEnd = () => {
    setIsTouched(false);
  };

  return (
    <Button
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      variant={variant}
      className={`touch__button position-relative rounded-1 ${className ? className : ''}`}
      {...rest}
    >
      {isTouched && (
        <div
          className={`${
            OutlineVariant[variant as keyof typeof OutlineVariant]
              ? OutlineVariant[variant as keyof typeof OutlineVariant]
              : ''
          } ${touchClass ? touchClass : ''} rounded-1`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background:
              ButtonVariant[variant as keyof typeof ButtonVariant] ?? '',
          }}
        />
      )}

      <span
        className={`position-relative d-flex ${OutlineVariant[variant as keyof typeof OutlineVariant] && isTouched ? 'text-white' : ''}`}
      >
        {children}
      </span>
    </Button>
  );
};

export default TouchButton;
