export const LS_API_APP = 'apiApp';
export const LS_API_TOKEN = 'apiToken';
export const BASE_URL = 'https://crm2.mtkrosberg.ru/';
export const LOGO_URL = 'api/gateways/mail-ru/?';

export const TOKEN_URL = 'api/gateways/v2/interface/getApiTokensByOneLink/';
export const FILTER_URL = 'api/gateways/v2/clients/getFilterData/';
export const CLIENT_URL = 'api/gateways/v2/clients/getList/';
export const USER_URL = 'api/gateways/v2/interface/getCurrentUser/';
export const PAGES_URL = 'api/gateways/v2/interface/getPages/';
export const TABS_URL = 'api/gateways/v2/clients/getTab/';
export const TECHNIC_URL = 'api/gateways/v2/clients/getTechnics/';
export const CHANGE_URL = 'api/gateways/v2/clients/updateClient/';
export const WS_DEFAULT_URL =
  'https://api.crm2.mtkrosberg.ru/gateway/v1/events/';
export const WS_NODE_URL = 'http://localhost:4000/gateway/v1/events/';
